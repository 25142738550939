import React, { Fragment, useEffect, useState } from 'react'
import "./RendezVousTemplate.css"
import { useParams } from 'react-router-dom'
import HeroSection from '../../components/HeroSection/HeroSection'
import Loader from '../../components/Loader/Loader'
import { bookAppointment, getBookingThematics, getBookingsAvailability } from '../../http/http'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { toast } from 'react-toastify';



const RendezVousTemplate = (props) => {

    // const { menu } = useParams()
    const [timeSlots, setTimeSlots] = useState({ "matin": [], "soir": [] })
    const [chosenDate, setChosenDate] = useState("");
    const [currentThematic, setCurrentThematic] = useState(null);
    const [chosenSlot, setChosenSlot] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [thematics, setThematics] = useState([]);
    const [bookingInfo, setBookingInfo] = useState({
        firstname: "",
        lastname: "",
        contact: "",
        email: "",
        notes: "",
        on_day: "",
        slot: null,
        thematic: null
    })

    const validateRDV = async () => {
        const { firstname, lastname, contact, email, on_day, thematic, slot } = bookingInfo;
        if (firstname !== "" && lastname !== "" && contact !== "" && thematic !== "" && on_day !== "" && slot !== "" && email !== "") {
            if (!isEmail(email)) {
                toast.warn("Le mail n'est pas valide. Veuillez corriger.");
                return;
            } else {
                setIsLoading(true)
                const resp = await bookAppointment(bookingInfo)
                if (resp.response && resp.response.status !== 200) {
                    console.log("Une erreur s'est produite ", resp)
                    toast.error("Une erreur s'est produite");
                    setIsLoading(false)
                } else {
                    console.log("booking success ", resp.data.success)
                    setIsLoading(false)
                    if (resp.data.success) {
                        setBookingInfo({
                            firstname: "",
                            lastname: "",
                            contact: "",
                            notes: "",
                            on_day: "",
                            slot: <null></null>,
                            thematic: null
                        });
                        setChosenDate("");
                        setChosenSlot("");
                        setCurrentThematic(null)
                        setIsLoading(false)
                        toast.success("Votre rendez-vous est pris avec succès.");
                    }
                }
            }
        } else {
            toast.warn("Veuillez renseigner toutes informations nécessaires à la prise de votre rendez-vous.");
        }
    }


    const loadThematics = async () => {
        const resp = await getBookingThematics()
        if (resp.response && resp.response.status !== 200) {
        } else {
            console.log(resp.data.data)
            setThematics(resp.data.data)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadThematics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('secondMenu-active')])


    const loadAvailabilities = async () => {
        const resp = await getBookingsAvailability(currentThematic, chosenDate)
        if (resp.response && resp.response.status !== 200) {
            console.log("Une erreur s'est produite ", resp)
        } else {
            setTimeSlots(resp.data)
        }

    }

    useEffect(() => {
        let controller = new AbortController()
        if (currentThematic && chosenDate) {
            loadAvailabilities();
        }

        return () => {
            controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentThematic, chosenDate])


    useEffect(() => {
        let controller = new AbortController()
        if (chosenDate && chosenSlot) {
            setBookingInfo({ ...bookingInfo, slot: chosenSlot, thematic: parseInt(currentThematic), on_day: chosenDate })
        }

        return () => {
            controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenDate, chosenSlot])

    // Fontion pour s'assurer que le mail est valide
    const isEmail = (chaine) => {
        // Expression régulière pour vérifier le format d'une adresse e-mail
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(chaine);
    }

    // Composant de l'heure
    const TimeSlot = (slot, time, id, isBooked) => <>{!isBooked ? <div title='Créneau disponible' onClick={() => setChosenSlot(id)} className={chosenSlot !== id ? "time-slot_unchosen" : (time === "morning" ? "time-slot-morning_chosen" : "time-slot-evening_chosen")}>
        {slot}
    </div> : <div className="time-slot_booked time-slot_unchosen" title='Créneau indisponible'>{slot}</div> }</>;

    return (<div className='pb-5' >
        {!isLoading ?
            <>
                <HeroSection />
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
                    {localStorage.getItem('secondMenu-active') !== "undefined" && <Breadcrumb.Item href="#">{(localStorage.getItem('secondMenu-active'))}</Breadcrumb.Item>}
                    <Breadcrumb.Item active>{JSON.parse(localStorage.getItem('active-menu'))?.name}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="">

                    <p className='time-slot-desription'>Vos informations</p>
                    <form className='rdv-form'>
                        <div className='row my-3'>
                            <div className='col-6 d-flex flex-column mb-5'>
                                <label className='rdv-label' htmlFor="lastname">Nom<span className='rdv-required'>&#42;</span></label>
                                <input name='lastname' placeholder='Entrer votre nom' className="rdv-input" onChange={(e) => setBookingInfo({ ...bookingInfo, lastname: e.target.value })} type="text" id='lastname' />
                            </div>
                            <div className='col-6 d-flex flex-column mb-5'>
                                <label className='rdv-label' htmlFor="firstname">Prénom<span className='rdv-required'>&#42;</span></label>
                                <input name='firstname' placeholder='Entrer votre prénom' className="rdv-input" onChange={(e) => setBookingInfo({ ...bookingInfo, firstname: e.target.value })} type="text" id='firstname' />
                            </div>
                            <div className='col-4 d-flex flex-column mb-5'>
                                <label className='rdv-label' htmlFor="email">Email<span className='rdv-required'>&#42;</span></label>
                                <input name='email' placeholder='Entrer votre mail' type="email" id='email' className="rdv-input" onChange={(e) => setBookingInfo({ ...bookingInfo, email: e.target.value })} />
                            </div>
                            <div className='col-4 d-flex flex-column mb-5'>
                                <label className='rdv-label' htmlFor="phone">Téléphone<span className='rdv-required'>&#42;</span></label>
                                <input name='phone' placeholder='Entrer votre téléphone' type="tel" id='phone' className="rdv-input" onChange={(e) => setBookingInfo({ ...bookingInfo, contact: e.target.value })} />
                            </div>
                            <div className='col-4 d-flex flex-column mb-5'>
                                <label className='rdv-label' htmlFor="thematic">Motif<span className='rdv-required'>&#42;</span></label>
                                <select name="thematic" className="rdv-input" id="thematic" onChange={(e) => setCurrentThematic(e.target.value)}>
                                    <option value="" disabled selected>Sélectionner un motif</option>
                                    {thematics.map(item => <option value={item.id}>{item.thematic}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-column mx-auto'>
                            <label htmlFor="notes" className="rdv-label">Note(facultatif)</label>
                            <textarea className='rdv-textarea' onChange={(e) => setBookingInfo({ ...bookingInfo, notes: e.target.value })} name="notes" id="notes" cols="30" rows="6"></textarea>
                        </div>
                    </form>
                    {currentThematic && <Fragment>
                        <p className='time-slot-desription'>Veuillez choisir le jour de votre rendez-vous<span className='required'>&#42;</span></p>
                        <input onChange={(e) => setChosenDate(e.target.value)} className='time-slot-input' type="date" name="time-slot-date" id="date-input" />
                    </Fragment>}
                    {currentThematic && chosenDate && <Fragment>
                        <div className='time-slot-container'>
                            <div className='time-slot-item'>
                                <div className="time-slot-item-morning">Matin</div>
                                {timeSlots["matin"]?.length === 0 ?
                                    <p className="time-slot-unavailable">Nous n'avons aucune disponibilité en matinée pour cette date</p>
                                    :
                                    <div className='d-flex flex-wrap'>
                                        {timeSlots["matin"]?.map(timeSlot => <div key={timeSlot.start}>{TimeSlot(timeSlot?.start + " - " + timeSlot?.end, "morning", timeSlot.id, timeSlot.is_booked)}</div>)}
                                    </div>}
                            </div>
                            <div className='time-slot-item'>
                                <div className='time-slot-item-evening'>Après-midi</div>
                                {timeSlots["soir"]?.length === 0 ?
                                    <p className="time-slot-unavailable">Nous n'avons aucune disponibilité en matinée pour cette date</p>
                                    :
                                    <div className='d-flex flex-wrap'>

                                        {timeSlots["soir"]?.map(timeSlot => <div key={timeSlot.slot}>{TimeSlot(timeSlot?.start + " - " + timeSlot?.end, "evening", timeSlot.id, timeSlot.is_booked)}</div>)}
                                    </div>}
                            </div>
                        </div>
                        <div>
                            <button onClick={validateRDV} className='time-slot-button-valider'>Valider</button>
                        </div>
                    </Fragment>}

                </div>
            </>
            :
            <Loader />}
    </div>
    )
}

export default RendezVousTemplate