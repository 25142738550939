import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import HeroSection from '../../components/HeroSection/HeroSection'
import TemplateMemberListBureauCard from '../../components/TemplateMemberListBureauCard/TemplateMemberListBureauCard'
import { getMembers } from '../../http/http'
import './MemberListTemplate.css'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Loader from '../../components/Loader/Loader'

const MemberListTemplate = () => {

const {menu} = useParams()
const [isLoading, setIsLoading] = useState(true);
const [members, setMembers] = useState({});

useEffect(() => {
  let controller = new AbortController();
  const loadMembers = async () => {
    const resp2 = await getMembers()
    if (resp2.response && resp2.response.status !== 200) {
        // console.log("data error ", resp2.response)
    } else {
        // console.log("data data load", resp2.data.data)
        const temp = [resp2.data.data["Président"][0], resp2.data.data["Rapporteur"][0], resp2.data.data["Conseiller"]]
        console.log(temp);
        setMembers({president: resp2.data.data["Président"][0], rapporteur: resp2.data.data["Rapporteur"][0], conseillers: resp2.data.data["Conseiller"]})
        setIsLoading(false)
    }
}

  loadMembers();
  return () => {
    controller.abort()
  }
}, [menu])

  return (
    <div>
      { !isLoading ?
        <>
     <HeroSection />
     <Breadcrumb>
            <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Connaître APDP</Breadcrumb.Item>
            <Breadcrumb.Item active>Membres</Breadcrumb.Item>
        </Breadcrumb> 
        <div className="member__list-template-card">
            {Object.keys(members).length !== 0 && <>
            <div className="member__list-template__header">Les Membres</div>
            <div className="member__list-template-conseiller-container row mt-5">
                <div className='col-12'><TemplateMemberListBureauCard type={"bureau"} picture={members.president?.converted_image["1089_959"]} name={members.president.title} role={"Président"} /></div>
                <div className='col-12'><TemplateMemberListBureauCard type={"bureau"} picture={members.rapporteur?.converted_image["1089_959"]} name={members.rapporteur.title} role={"Rapporteur"} /></div>
            </div>
            <div className="member__list-template-conseiller-container d-flex justify-content-evenly flex-wrap">
                {members.conseillers.map((item, index) => <div className='mx-1' key={index}><TemplateMemberListBureauCard type={"conseiller"} picture={item.converted_image["1089_959"]} name={item.title} role={"Conseiller"} /></div>)}  
            </div>
            </>}
        </div>
        {Object.keys(members).length === 0 && <h2 className='m-auto p-5'>Aucune Donnée</h2>}
        </>  
            :
            <Loader /> }
    </div>
  )
}

export default MemberListTemplate