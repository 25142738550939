import React, { useEffect, useState } from 'react'
import './PaysMembresListTemplate.css'
import { useParams } from 'react-router-dom'
import HeroSection from '../../components/HeroSection/HeroSection';
import { Breadcrumb } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';
import { getATypeOfArticles } from '../../http/http';
import { PaysMembresElementTemplate } from '../../components/PaysMembresElementTemplate/PaysMembresElementTemplate';

export const PaysMembresListTemplate = () => {


  const { menu } = useParams();
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const jsonData = [
    {
      "name": "Législations nationales sur la protection des données personnelles",
      "sections": [
        {
          "name": "EUROPE",
          "items": [
            {
              "title": "Allemagne",
              "sub_title": "http://www.datenschutz.de/",
              "content": "<p>Allemagne</p>",
              "image_url": "https://api.apdp.bj/storage/5664531fcbbb1683a55f76cf88610b6d/germany.jpg",
              "type": "Pays Membres"
            }
          ]
        },
        {
          "name": "AFRIQUE",
          "items": [
            {
              "title": "Mali",
              "sub_title": "http://apdp.ml/",
              "content": "<p><span style=\"color: rgb(31, 31, 31);\">Mali</span></p>",
              "image_url": "https://api.apdp.bj/storage/8fd4049c263824025968aea599bef4c0/mali.jpg",
              "type": "Pays Membres"
            },
            {
              "title": "Sénégal",
              "sub_title": "http://www.cdp.sn/",
              "content": "<p>Sénégal</p>",
              "image_url": "https://api.apdp.bj/storage/712b8f860dc19f908c4da0b8b02cd41f/senegal.jpg",
              "type": "Pays Membres"
            }
          ]
        }
      ],
      "items": []
    },
    {
      "name": "Associations et Réseaux de protection des données personnelles",
      "sections": [],
      "items": [
        {
          "title": "ICDPPC",
          "sub_title": "https://icdppc.org/",
          "content": "<p><span style=\"color: rgb(31, 31, 31);\">Conférence internationale des commissaires à la protection des données et à la vie privée (ICDPPC)</span></p>",
          "image_url": "https://api.apdp.bj/storage/7be3e60bb79026edf210ed493209752f/ICDPPC_2.jpg",
          "type": "Pays Membres"
        },
        {
          "title": "AFAPDP",
          "sub_title": "https://www.afapdp.org/",
          "content": "<p><span style=\"color: rgb(31, 31, 31);\">Association francophone des autorités de protection des données personnelles (AFAPDP)</span></p>",
          "image_url": "https://api.apdp.bj/storage/29dfb3dbee3e87024f48edd1192112a2/afapdp_1.jpg",
          "type": "Pays Membres"
        }
      ]
    }
  ];


  useEffect(() => {
    const loadArticlesData = async () => {
      console.log("subMenu load", menu)
      const resp = await getATypeOfArticles(menu, 1)
      if (resp.response && resp.response.status !== 200) {
        console.log("data data error ", resp.response)
      } else {
        setArticles(resp.data)
        setIsLoading(false)
      }
    }

    loadArticlesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return (
    <div className='pb-5' >
      {!isLoading ?
        <>
          <HeroSection />
          <Breadcrumb>
            <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
            {localStorage.getItem('secondMenu-active') !== "undefined" && <Breadcrumb.Item href="#">{(localStorage.getItem('secondMenu-active'))}</Breadcrumb.Item>}
            <Breadcrumb.Item active>{JSON.parse(localStorage.getItem('active-menu'))?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            {articles.map(article =>
              <>
                <h3 className='section-title'>{article?.name}</h3>
                {(article.sections.length > 0) ?
                  <>
                    <div className='mx-auto'>
                      {article?.sections.map(section => {
                        return <div key={section.name}>
                          <div className='continent-title'>{section.name}</div>
                          <div className='mx-auto pays-container d-flex flex-wrap justify-evenly'>
                            {section.items.map(item =>
                              <PaysMembresElementTemplate key={item.image_url}
                                title={item.title}
                                image_url={item.image_url}
                                link={item.sub_title} />)}
                          </div>
                        </div>
                      })}
                    </div>
                  </>
                  :
                  <>
                    <div className='mx-auto pays-container d-flex flex-wrap justify-evenly'>
                      {article?.items.map(item =>
                        <PaysMembresElementTemplate key={item.image_url}
                          title={item.title}
                          image_url={item.image_url}
                          link={item.sub_title} />)}
                    </div>
                  </>
                }
              </>)}

          </div>
        </>
        :
        <Loader />}


    </div>
  )
}
