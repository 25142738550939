import React, { useEffect } from 'react'
import Home from '../Home/Home'
// import { Route, Router, Switch, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "../Auth/Auth"
import VosDroits from '../VosDroits/VosDroits';
import DetailsDroit from '../DetailsDroit/DetailsDroit';
import DetailsCommunique from '../DetailsCommuniqué/DetailsCommunique';
import DetailsConseils from '../DetailsConseils/DetailsConseils';
import DetailsActivite from '../DetailsActivité/DetailsActivite';
import VosDevoirs from '../VosDevoirs/VosDevoirs';
import DetailsDevoir from '../DetailsDevoir/DetailsDevoir';
import FaireUneDemande from '../FaireUneDemande/FaireUneDemande';
import Communiques from '../Communiqués/Communiques';
import rester_informe from "../../assets/icons/rester_informe.png"
import vous_opposer from "../../assets/icons/vous_opposer.jpg"
import verifier_vos_donnees from "../../assets/icons/verifier_vos_donnees.jpg"
import rectifier_vos_donnees from "../../assets/icons/rectifier_vos_donnees.jpg"
import deferencer_contenu from "../../assets/icons/deferencer_un_contenu.jpg"
import effacer_vos_donnees from "../../assets/icons/effacer_vos_donnees.jpg"
import emporter_vos_donnees from "../../assets/icons/emporter_vos_donnees.jpg"
import demander_intervention_humaine from "../../assets/icons/demander_une_intervention_humaine.jpg"
import geler_vos_donnees from "../../assets/icons/geler_utilisation_de_vos_donnees.jpg"
import fichiers_police from "../../assets/icons/fichiers_de_police.jpg"
import droit_ficoba from "../../assets/icons/droit_acces_ficoba.png"
import Evenements from '../Evenements/Evenements';
import Commissaire from '../Commissaire/Commissaire';
import DetailsEvenement from '../DetailsEvenement/DetailsEvenement';
import DetailsFormRequest from '../DetailsFormRequest/DetailsFormRequest';
import FormulaireCourrier from '../FormulaireCourrier/FormulaireCourrier';
import Activites from '../Activités/Activites';
import AppelsOffres from '../AppelsOffres/AppelsOffres';
import FAQ from '../FAQ/FAQ';
import Mission from '../Mission/Mission';
import MissionDetails from '../MissionDetails/MissionDetails';
import Quiz from '../Quiz/Quiz';
import RapportsAnnuels from '../RapportsAnnuels/RapportsAnnuels';
import Membres from '../Membres/Membres';
import President from '../President/President';
import LesLois from '../LesLois/LesLois';
import LaLoi from '../LaLoi/LaLoi';
import OutilsConformite from '../OutilsConformite/OutilsConformite';
import Decrets from '../Decrets/Decrets';
import Arretes from '../Arretes/Arretes';
import EspaceRT from '../EspaceRT/EspaceRT';
import Deliberations from '../Deliberations/Deliberations';
import Cooperations from '../Cooperations/Cooperations';
import Conseils from '../Conseils/Conseils';
import MentionsLegales from '../MentionsLegales/MentionsLegales';
import PaysMembres from '../PaysMembres/PaysMembres';
import TextesCommunautaires from '../TextesCommunautaires/TextesCommunautaires';
import EspaceDPO from '../EspaceDPO/EspaceDPO';
import DynamiqueForm from '../Dynamique/DynamiqueForm';
import Procedures from '../Procedures/Procedures';
import Photothèque from '../Photothèque/Photothèque';
import Vidéothèque from '../Vidéothèque/Vidéothèque';
import PlansDePassation from '../PlansDePassation/PlansDePassation';
import Contact from '../Contact/Contact';
import Recommandations from '../Recommandations/Recommandations';
import StatutDemande from '../StatutDemande/StatutDemande';
import MesDemandes from '../MesDemandes/MesDemandes';
import DetailsDemande from '../DetailsDemande/DetailsDemande';
import BreakNewsDetails from '../BreakNewsDetails/BreakNewsDetails';
import Actualités from '../Actualités/Actualités';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import GlobalResearch from '../GlobalResearch/GlobalResearch';
import GlobalSearchItem from '../GlobalSearchItem/GlobalSearchItem';
import ActualityListTemplate from '../../templates/ActualityListTemplate/ActualityListTemplate';
import ActualityItemTemplate from '../../templates/ActualityItemTemplate/ActualityItemTemplate';
import DocumentationListTemplate from '../../templates/DocumentationListTemplate/DocumentationListTemplate';
import DocumentationItemTemplate from '../../templates/DocumentationItemTemplate/DocumentationItemTemplate';
import MemberListTemplate from '../../templates/MemberListTemplate/MemberListTemplate';
import MemberItemTemplate from '../../templates/MemberItemTemplate/MemberItemTemplate';
import VideothequeTemplate from '../../templates/VideothequeTemplate/VideothequeTemplate';
import Core from '../../appShell/Core';
import CoreItem from '../../appShell/CoreItem';
import DetailsFormation from '../../components/DetailsFormation/DetailsFormation';
import ForumsDiscussionTemplate from '../../templates/ForumsDiscussionTemplate/ForumsDiscussionTemplate';
import ForumDiscussionItemTemplate from '../../templates/ForumDiscussionItemTemplate/ForumDiscussionItemTemplate';


const Content = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
                <Routes>
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/query/:form_type/:query_id" element={<DetailsDemande />} />                   
                    <Route path="/actualites/:menu" element={<Core model={"actualites"}/>} />
                    <Route path="/actualites/:menu/:id/:page_number" element={<CoreItem type={'actualite'} />} />
                    <Route path="/communiques/:menu" element={<Core model={"communiques"}/>} />
                    <Route path="/communiques/:menu/:id/:page_number" element={<CoreItem type={'actualite'} />} />
                    <Route path="/documentations/:menu" element={<Core model={"documentations"}/>} />
                    <Route path="/documentations/:menu/:id/:page_number" element={<CoreItem type={'documentation'} />} />
                    <Route path="/videotheque/:menu" element={<Core model={"videotheque"}/>} />
                    <Route path="/videotheque/:menu/:id/:page_number" element={<CoreItem />} />
                    <Route path="/membres/:menu" element={<Core model={"membres"}/>} />
                    <Route path="/biographique/:menu" element={<Core model={"biographique"} />} />
                    <Route path="/mise_en_conformite/:menu" element={<Core model={"mise_en_conformite"} />} />
                    <Route path="/redirection_url/:menu" element={<Core model={"redirection_url"} />} />
                    <Route path="/pays_membres/:menu" element={<Core model={"pays_membres"}/>} />
                    <Route path="/prise_rdv" element={<Core model={"prise_rdv"}/>} />
                    
                    <Route path="/forums-discussions/:id" element={<ForumDiscussionItemTemplate />} />

                    <Route path="/actualites/actualites/:actualite_id" element={<Actualités />} />
                    
                    <Route path="redirection_url/faire-une-demande" element={<FaireUneDemande />} />
                    <Route path="redirection_url/statut-demande" element={<StatutDemande />} />
                    <Route path="redirection_url/mes-demandes" element={<MesDemandes />} />
                    
                    <Route path="redirection_url/forums-discussions" element={<ForumsDiscussionTemplate />} />

                    <Route path="/faqs/:menu" element={<Core model={"faqs"}/>} />

                    <Route path="/activites" element={<ActualityListTemplate />} />
                    <Route path="/communiques" element={<ActualityListTemplate />} />
                    <Route path="/actualites/:actualite_id" element={<ActualityListTemplate />} />
                    <Route path="/evenements" element={<ActualityListTemplate />} />

                    <Route path="/phototheque" element={<Photothèque />} />

                    <Route path="/videotheque" element={<VideothequeTemplate />} />

                    <Route path="/communique/:communique_id" element={<DetailsCommunique />} />
                    <Route path="/activite/:activity_id" element={<DetailsActivite />} />
                    <Route path="/evenement/:evenement_id" element={<DetailsEvenement />} />


                    <Route path="/contact" element={<Contact />} />
                    <Route path="/template-actuality" element={<ActualityListTemplate />} />
                    <Route path="/template-documentation" element={<DocumentationListTemplate />} />
                    <Route path="/template-actuality-item" element={<ActualityItemTemplate />} />
                    <Route path="/template-documentation-item" element={<DocumentationItemTemplate />} />
                    <Route path="/template-member" element={<MemberListTemplate />} />
                    <Route path="/template-member-item" element={<MemberItemTemplate />} />

                    <Route path="/plans-de-passation" element={<PlansDePassation />} />
                    <Route path="/faq" element={<FAQ />} />

                    <Route path="/breaknews/:breaknews_id/:page_number" element={<BreakNewsDetails />} />

                    <Route path="/pays-membres" element={<PaysMembres />} />
                    <Route path="/mentions-legales" element={<MentionsLegales />} />
                    <Route path="/vos-demarches" element={<Auth />} />
                    <Route path="/faire-une-demande" element={<FaireUneDemande />} />
                    <Route path="/formulaire-du-courrier" element={<FormulaireCourrier />} />
                    <Route path="/vos-droits/:droit_id" element={<DetailsDroit />} />
                    <Route path="/vos-droits" element={<VosDroits />} />
                    <Route path="/vos-devoirs/:devoir_id" element={<DetailsDevoir />} />
                    <Route path="/vos-devoirs" element={<VosDevoirs />} />
                    <Route path="/appels-d-offres" element={<AppelsOffres />} />

                    <Route path="/espace-rt" element={<EspaceRT />} />
                    <Route path="/espace-dpo/:id_formation" element={<DetailsFormation />} />
                    <Route path="/espace-dpo" element={<EspaceDPO />} />
                    <Route path="/statut-demande" element={<StatutDemande />} />
                    <Route path="/mes-demandes" element={<MesDemandes />} />
                    <Route path="/mes-demandes/:id_request" element={<DetailsFormRequest/>} />
                    <Route path="/formulaire/:id" element={<DynamiqueForm />} />
                    <Route path="/recherche-globale" element={<GlobalResearch />} />
                    <Route path="/recherche-globale/:recherche_id" element={<GlobalSearchItem />} />

                    < Route path="/" element={<Home />} >
                    </Route>
                    <Route path="*" element={<Home />} />
                </Routes>
            {/* </BrowserRouter> */}
            {/* </Container> */}
        </div>
    )
}

export default Content
