import React from "react";
import { Row, Col } from "reactstrap"
import "./Footer.css"
import apdp_logo from "../../assets/images/logoapdp.svg"
import green_call_apdp from "../../assets/icons/green_call_apdp.jpeg"
import { FaFacebook, FaTwitter, FaYoutube, FaWhatsappSquare, FaWhatsapp, FaLinkedin, FaTiktok } from "react-icons/fa"
import { getVisitsNumber, submitNewsletterForm } from "../../http/http";
import { DOMAIN_URL } from "../../constants/Constant";
import { toast, ToastContainer } from "react-toastify";

const Footer = () => {

  const [name, setName] = React.useState("")
  const [firstname, setFirstname] = React.useState("")
  const [mail, setMail] = React.useState("")

  const onSubmit = async () => {
    // console.log("data ", {name, firstname, mail})

    const data = {
      nom: name,
      prenom: firstname,
      mail: mail
    }

    const resp = await submitNewsletterForm(data)
    // // console.log("data typeof", resp?.data)
    try {
      if (resp?.data?.success) {

        setName("")
        setFirstname("")
        setMail("")

        toast.success("Souscription réussie !!!");

      } else {
        // console.log("resp footer", resp.response)
        toast.error(resp?.response?.data?.message);
      }
    } catch (error) {
      // console.log("error ", error)
      toast.error("Quelque chose s'est mal passé.");

    };

  }



  const redirectSocialMedia = (type) => {
    if (type === 'fb') {
      window.open("https://www.facebook.com/apdp.beninofficiel", '_blank')
    }
    if (type === 'tw') {
      window.open("https://twitter.com/APDP_BENIN", '_blank')
    }
    if (type === 'yt') {
      window.open('https://www.youtube.com/channel/UCgKoa_HnOOyxyx8B1CdhzXQ', '_blank');
      // window.location.replace('https://www.youtube.com/channel/UCgKoa_HnOOyxyx8B1CdhzXQ')
    }
    if (type === 'wa') {
      window.location.replace('https://wa.me/22969550000')
    }
    if (type === 'in') {
      window.open("https://www.linkedin.com/company/apdpbenin", '_blank')
    }
    if (type === 'tt') {
      window.open("https://www.tiktok.com/@apdpbenin", '_blank')
    }
  }

  const goHome = () => {
    window.location.replace("/")
  }

  return <div className="pg-footer">
    <ToastContainer />
    <div className="footer">

      <div className="footer-copyright">
        <div className="footer-content">
          <div className="col-sm-4 col-xs-12 text-center">
            <div className="footer-logo">
              <a className="footer-logo-link" href="#">
                <span className="hidden-link-text">LOGO</span>
                <div style={{ cursor: "pointer" }} onClick={goHome}>
                  <object data={apdp_logo} alt="LOGO" className="img-fluid" type="image/svg+xml"></object>
                </div>
              </a>
            </div>

            <div style={{
              marginTop: "10%",
            }}>
              <div className="footer-menu">
                <h2 style={{ textAlign: 'left' }} className="footer-menu-name"> INFORMATIONS </h2>
                <ul id="menu-company" className="footer-menu-list">
                  <li style={{ textAlign: 'left' }} className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="/mise_en_conformite/politique_de_confidentialite">Politique de confidentialité</a>
                  </li>
                  <li style={{ textAlign: 'left' }} className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="/mise_en_conformite/mentions_legales_(apdp)">Mentions Légales</a>
                  </li>

                </ul>
              </div>

            </div>

          </div>

          <div className="col-sm-4 col-xs-12 text-center" >
            <div className="footer-menu"
            // style={{ paddingRight: 30 }}
            >

              <div >
                <h5 style={{
                  color: "white",
                  textAlign: "left",
                  maxWidth: 300,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 10
                }}>Recevez l'actualité en vous abonnant à notre Support</h5>

                <input value={name} onChange={(e) => setName(e.target.value)} style={{ borderRadius: 5, maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }} type="text" className="form-control mb-2" placeholder="Votre nom" aria-label="Recipient's username" aria-describedby="basic-addon2" />

                <input value={firstname} onChange={(e) => setFirstname(e.target.value)} style={{ borderRadius: 5, maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }} type="text" className="form-control mb-2" placeholder="Votre prénom" aria-label="Recipient's username" aria-describedby="basic-addon2" />

                <div className="input-group mb-3">
                  <input value={mail} onChange={(e) => setMail(e.target.value)} style={{ borderRadius: 5, maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }} type="mail" className="form-control" placeholder="Votre e-mail" aria-label="Recipient's username" aria-describedby="basic-addon2" />

                </div>
                <button onClick={onSubmit} className="footer-subscribe-button" style={{ maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }}>S'abonner</button>

              </div>

            </div>
          </div>

          <div className="col-sm-4 col-xs-12 text-center">
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title" style={{ textAlign: 'left' }}>CONTACTS</h2>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}>

                <div className='d-flex justify-content-left align-items-center'><i className="ri-map-pin-2-fill fw-bold mx-1" style={{ fontSize: 30, marginRight: 10 }}></i><span style={{ font: 'normal normal normal 14px/20px Montserrat' }}>Rue 6 . 076 El Marzouk Joël  COTONOU</span></div>
                <div className='d-flex justify-content-left align-items-center'><i className="ri-mail-line fw-bold mx-1" style={{ fontSize: 30, marginRight: 10 }}></i><span style={{ font: 'normal normal normal 14px/20px Montserrat' }}>contact@apdp.bj</span></div>
                <div className='d-flex justify-content-left align-items-center'> <i className="ri-phone-fill fw-bold mx-1" style={{ fontSize: 30, marginRight: 10 }}></i><span style={{ font: 'normal normal normal 14px/20px Montserrat' }}>(+ 229 ) 21 32 57 88</span></div>


                <div className='d-flex  align-items-start'>
                  <div className="d-flex flex-row justify-content-between mx-auto" style={{ display: "block !important" }}>
                    <img alt="green call" style={{ height: 30, width: 30, borderRadius: 50, marginTop: 5 }} src={green_call_apdp} />
                    <FaFacebook size={30} style={{ color: `#2289FF`, margin: 5, cursor: 'pointer' }} onClick={() => redirectSocialMedia('fb')} />
                    <FaTwitter size={30} style={{ color: "#2289FF", backgroundColor: "#FFFFFF", borderRadius: "20px", padding: "3px", margin: 5, cursor: 'pointer' }} onClick={() => redirectSocialMedia('tw')} />
                    <FaYoutube size={30} style={{ color: `red`, margin: 5, cursor: 'pointer' }} onClick={() => redirectSocialMedia('yt')} />
                    <FaWhatsapp size={30} style={{ color: `green`, margin: 5, cursor: 'pointer' }} onClick={() => redirectSocialMedia('wa')} />
                    <FaLinkedin size={30} style={{ color: `#2289FF`, margin: 5, cursor: 'pointer' }} onClick={() => redirectSocialMedia('in')} />
                    <FaTiktok size={30} style={{ color: `gray`, margin: 5, cursor: 'pointer' }} onClick={() => redirectSocialMedia('tt')} />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div className="mx-auto mt-4 mb-5 text-center">

        <p className="visitor-number-p" style={{ visibility: 0 }}><span id="visits" className="visitor-number-span">

        </span></p>
      </div>

      <p className="footer-copyright-text mb-5">
        <a className="footer-copyright-link text-white" href="#" target="_self">
          <span>Copyright {new Date().getFullYear()} © APDP. Tous droits réservés.</span>
        </a>
      </p>
      <div className="flag-look-like">
        <div className="col green-block"></div>
        <div className="col yellow-block"></div>
        <div className="col red-block"></div>
      </div>
    </div>
  </div>

};

export default Footer;
