import React from 'react'
import { convertDate, convertTime } from '../../helpers/utils'
import { useNavigate } from 'react-router-dom'

const FormationSuivie = ({ trainingsDone }) => {

    const navigate = useNavigate();
    return (
        <div>

            {trainingsDone?.data?.length !== 0 ? (<table className='custom-table'>
                <thead className="">
                    <tr className='table-row'>
                        <th>Titre</th>
                        <th>Description</th>
                        <th>Date      </th>
                        <th>Heure      </th>
                        <th>Formateur      </th>
                        <th>Auteur      </th>
                        <th>Actions      </th>
                    </tr>
                </thead>
                <tbody>
                    {trainingsDone?.data?.map((item, index) => <tr key={index} className='table-row'>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td><strong>{convertDate(item.from_date)}</strong> au <strong>{convertDate(item.to_date)}</strong></td>
                        <td><strong>{convertTime(item.from_time)}</strong> à <strong>{convertTime(item.to_time)}</strong></td>
                        <td>{item.former}</td>
                        <td>{item.created_by[0]?.name}</td>
                        <td>
                            {/* <button className='table-button'>TDR</button> */}
                            <button className='table-button' onClick={() => navigate(`/espace-dpo/${item.uid}`)}>Détails</button>
                        </td>
                    </tr>)}

                </tbody>
            </table>) :
                <h1 className='mx-auto mt-20'>No Data</h1>
            }
        </div>
    )
}

export default FormationSuivie