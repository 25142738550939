import React, { useState, useEffect } from 'react'
import './ForumDiscussionItemTemplate.css'
import image from '.././../assets/images/img7.jpg'
import { useNavigate, useParams } from 'react-router-dom'
import { addCommentForum, getATypeOfArticles, getForum } from '../../http/http'
import Loader from '../../components/Loader/Loader'
import discuter from "../../assets/images/discuter.png"
import { BsFillCursorFill } from "react-icons/bs"
import { convertDate, sanitizeInput } from '../../helpers/utils'


const ForumDiscussionItemTemplate = () => {
    const navigate = useNavigate()
    let { id } = useParams();

    const [data, setData] = useState();
    const [comment, setComment] = useState("")
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        loadData();
    }, [id])

    const loadData = async () => {
        const resp = await getForum(id)
        if (resp.response && resp.response.status !== 200) {
            // console.log("data error ", resp.response)
        } else {
            setData(resp.data.data)
            setIsLoading(false)

        }
    }

    const sendComment = async () => {
        const token = localStorage.getItem("user_token");
        const sanitizedComment = sanitizeInput(comment);
        const response = await addCommentForum(id, token, sanitizedComment)
        if (response.response && response.response.status !== 200) {
            // console.log("data error ", response.response)
        } else {
            setComment("");
            loadData();
            setIsLoading(false);

        }
    }



    const dataSample = {
        id: "2",
        subject: "Les droits des utilisateurs",
        date: "17-07-2022",
        description: "Nulla esse sint aliquip elit culpa qui non nisi excepteur aute commodo sit magna.",
        comments: [
            {
                user: "John DOE",
                content: "Okay, alors continuons d'implémenter ces fonctionnalités pour vite en finir",
                date: "04-08-2021",

            },
            {
                user: "Jane DOE",
                content: "Here we are to work and try to get this thing figured out",
                date: "17-07-2022",

            },
            {
                user: "Paul NEWTON",
                content: "This is not what you think about",
                date: "16-01-2023",
            },
            {
                user: "Capelo KITRICH",
                content: "Nous pensons que nos droits à l'image devraient être mieux gérés",
                date: "13-04-2023",

            },
        ]
    }

    return (
        <div>
            {!isLoading ?
                <>
                    <div className="forum__item-template__header">{data?.topic}</div>
                    <div className="forum__item__description ql-editor text-center" dangerouslySetInnerHTML={{ __html: (data && data?.body) }}></div>
                    <div className="forum__item-template__content ql-editor" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <div className="text-content">

                            {data?.comments?.map(item => <div className='forum-message-row mb-3 mt-3'>
                                <div className='forum-user-container d-flex w-100 justify-content-between'>
                                    {/* <img className="forum-message-row-img" src={discuter} alt="message-row" /> */}
                                    <div className="forum-message-row-user">{item.creator?.name}</div>
                                <div className="forum-message-row-date"> {convertDate(item.created_at?.substring(0, 10))}</div>
                                </div>

                                <div className='text-left'>

                                    <div className="forum-message-row-message ql-editor" dangerouslySetInnerHTML={{ __html: (item?.body) }}></div>
                                </div>
                                <br />
                            </div>)}

                            {!(localStorage.getItem("user_token")) ? <><div className='forum-must-signin'>Vous devez être connecté(e) pour ajouter un commentaire</div>
                                <div className="d-flex  w-100"><button onClick={() => {

                                    localStorage.setItem("redirect_url", window.location.pathname);
                                    // window.location = "/auth";
                                    navigate(`/auth`)
                                }} className="forum__item__read-button mx-auto">Se Connecter</button></div>

                            </> :
                                <div className='forum-input-container'>
                                    <input value={comment} onChange={(e) => setComment(e.target.value)} className='forum-input' type="text" placeholder='Ajoutez votre commentaire ici...' />
                                    <BsFillCursorFill onClick={sendComment} className='forum-button' src={BsFillCursorFill} alt='send_msg' />
                                </div>}

                            <div className="buttons-content d-flex justify-content-center">
                                <button onClick={() => navigate(`/forums-discussions`)} className="forum__item__back-button">Retour</button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader />}
        </div>)
}

export default ForumDiscussionItemTemplate

