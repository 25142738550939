import React, { useState, useEffect } from 'react';
import "./StatutDemande.css";
import ActualiteCarousel from "../../components/ActualiteCarousel/ActualiteCarousel";
import { ToastContainer, toast } from 'react-toastify';
import { getRequestedQuery, submitAdditionalInfo, submitFormSection } from '../../http/http';
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Input, InputGroup, Label, FormGroup, Button } from 'reactstrap';



const StatutDemande = () => {

    const navigate = useNavigate()
    const [status, setStatus] = useState();
    const [query, setQuery] = useState({});

    const [formData, setFormData] = useState({
        answers: [],
        user: localStorage.getItem("user_id")
    });

    const [fields, setFields] = useState([])


    function answerExists(question) {
        const tab = formData["answers"];
        return tab.some(function (el) {
            return el?.question === question;
        });
    }

    const updateValue = (answerObject) => {
        const old = formData;
        if (!answerExists(answerObject?.question)) {
            old["answers"].push(answerObject);
        } else {
            for (let i = 0; i < old["answers"].length; i++) {
                const element = old["answers"][i];
                if (element?.question === answerObject?.question) {
                    old["answers"][old["answers"].indexOf(element)] = answerObject;
                }
            }
        }
        setFormData(old);
    }


    const loadQueryData = async (e) => {
        e.preventDefault();
        var ref_number = encodeURI(e.target[1].value);
        const resp = await getRequestedQuery(ref_number);
        if (!resp.data.data.id) {
            document.getElementById("ref_number_input").className = "form-control is-invalid"
            setQuery({});
        } else {
            document.getElementById("ref_number_input").className = "form-control"
            setQuery(resp.data.data);
            setFields(resp.data.data?.additional_infos)
        }
    }

    // CustomField 
    const CustomField = ({ field, updateValue }) => {
        const [currentRadio, setRadio] = useState(null);
        const [currentCheckBoxes, setCheckboxes] = useState([]);

        let fileInputsData = [];


        const readBlob = file => new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = file => {
                // // console.log(file.target.result);
                // setFileInputsData([...fileInputsData, file.target.result]);
                fileInputsData.push(file.target.result);
                // console.log(fileInputsData);
                // console.log(fileInputsData.length);
                updateValue({
                    answer_file: fileInputsData,
                    // choice: {'group': field.option_group_id, 'value': null},
                    choice: field.option_choices[0].id,
                    question: field.id
                })
                resolve(file);
            };
            reader.readAsDataURL(file);
        });

        // return <input style={{width: 300, height: 40}} className="mb-3"/>
        // Start Field
        if (field.input_type.element === 'select' || field.input_type.element === 'multi-select') {
            return (
                <>
                    <FormGroup id='input_div'>
                        <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "17px", color: '#0d6efd', fontFamily: 'Montserrat', }} string={field.id} for={field.id}>
                            {field.name}{field.answer_required && <span style={{ color: "red" }}>(Requis)</span>}
                        </label><br />
                        <Input
                            className='form-control'
                            style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", padding: "10px 15px", width: "100%", height: "50px", minWidth: "200px", fontFamily: 'Montserrat', }}
                            defaultValue={"None"}
                            name={field.id}
                            required={field.answer_required}
                            id={field.id}
                            type="select"
                            onChange={(e) => {
                                updateValue({
                                    answer_text: e.target.value,
                                    // choice: {'group': field.option_group_id, 'value': e.target.id},
                                    choice: e.target.key,
                                    question: field.id
                                })
                            }}>
                            <option value="None"></option>
                            {field.option_choices.map(op => <option key={op.pivot.option_choice_id} value={op.name} name={op.pivot.related_section}>{op.name}</option>)}
                        </Input>
                        <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                            Champ invalide!!!
                        </div>
                    </FormGroup>
                </>
            )

        } else {
            if (field.input_type.element === 'textarea') {
                return (
                    <>
                        <FormGroup id='input_div'>
                            <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "17px", color: '#0d6efd', fontFamily: 'Montserrat', }} string={field.id} for={field.id}>
                                {field.name} {field.answer_required && <span style={{ color: "red" }}>(Requis)</span>}</label><br />
                            <textarea
                                className='form-control'
                                style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", padding: "10px 15px", minWidth: "200px", fontFamily: 'Montserrat', }}
                                name={field.id}
                                required={field.answer_required}
                                id={field.id}
                                rows={6}
                                cols={60}
                                onChange={(e) => {
                                    updateValue({
                                        answer_text: e.target.value,
                                        // choice: {'group': field.option_group_id, 'value': null},
                                        choice: field.option_choices[0].id,
                                        question: field.id
                                    })
                                }} />
                            <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                                Champ invalide!!!
                            </div>
                        </FormGroup>
                    </>
                )
            } else if (field.input_type.type === 'radio') {
                return (
                    <>
                        <FormGroup style={{ textAlign: 'left', margin: "20px 0px", }}>
                            <Label string={field.id} style={{ fontWeight: "normal", backgroundColor: "white", fontSize: "17px", color: '#0d6efd', fontFamily: 'Montserrat', }}>
                                {field.name}{field.answer_required && <span style={{ color: "red" }}>(Requis)</span>}
                            </Label>
                            {field.option_choices.map(op =>
                                <div style={{ color: "black", display: "flex" }}>
                                    <Input
                                        className="form-control"
                                        style={{ minWidth: "15px", width: "17px", height: "17px", marginRight: 10, fontFamily: 'Montserrat', }}
                                        type="radio"
                                        checked={currentRadio === op.name}
                                        onChange={(e) => {
                                            setRadio(e.target.value)
                                            updateValue({
                                                answer_text: e.target.value,
                                                // choice: {'group': field.option_group_id, 'value': op.id},
                                                choice: e.target.id,
                                                question: field.id
                                            })
                                        }}
                                        id={op.pivot.option_choice_id}
                                        value={op.name}
                                        name={op.pivot.related_section} />
                                    <Label
                                        string={op.name}
                                        for={op.name}
                                        style={{ fontWeight: "normal", fontSize: "15px", top: "-2px", right: "-5px", position: "relative", marginTop: 5, fontFamily: 'Montserrat', }}>
                                        {op.name}
                                    </Label>
                                </div>
                            )}
                        </FormGroup>
                    </>
                )
            } else if (field.input_type.type === 'checkbox') {
                return (
                    <>
                        <FormGroup style={{ textAlign: 'left' }}>
                            <Label string={field.id} style={{ fontWeight: "normal", backgroundColor: "white", fontSize: "17px", color: '#0d6efd', fontFamily: 'Montserrat', }}>
                                {field.name}{field.answer_required && <span style={{ color: "red" }}>(Requis)</span>}
                            </Label>
                            {field.option_choices.map(op =>
                                <div style={{ display: "flex" }}>
                                    <Input
                                        className="form-control"
                                        style={{ minWidth: '16px', height: '16px', marginRight: '10px', position: 'relative', fontFamily: 'Montserrat', }}
                                        type="checkbox"
                                        checked={currentCheckBoxes.includes(op.name)}
                                        onChange={(e) => {
                                            // setCheckbox(e.target.value)
                                            if (e.target.checked) {
                                                if (!currentCheckBoxes.includes(e.target.value)) {
                                                    setCheckboxes([...currentCheckBoxes, e.target.value])
                                                    updateValue({
                                                        answer_text: [...currentCheckBoxes, e.target.value].join('; '),
                                                        // choice: {'group': field.option_group_id, 'value': op.id},
                                                        choice: e.target.id,
                                                        question: field.id
                                                    })
                                                }
                                            } else {
                                                // console.log(currentCheckBoxes);
                                                setCheckboxes([...currentCheckBoxes.slice(0, currentCheckBoxes.indexOf(e.target.value)), ...currentCheckBoxes.slice(currentCheckBoxes.indexOf(e.target.value) + 1)])
                                                updateValue({
                                                    answer_text: [...currentCheckBoxes.slice(0, currentCheckBoxes.indexOf(e.target.value)), ...currentCheckBoxes.slice(currentCheckBoxes.indexOf(e.target.value) + 1)].join('; '),
                                                    // choice: {'group': field.option_group_id, 'value': op.id},
                                                    choice: e.target.id,
                                                    question: field.id
                                                })
                                                // console.log([...currentCheckBoxes.slice(0, currentCheckBoxes.indexOf(e.target.value)), ...currentCheckBoxes.slice(currentCheckBoxes.indexOf(e.target.value) + 1)].join('; '));
                                            }
                                        }}
                                        id={op.pivot.option_choice_id}
                                        value={op.name}
                                        name={op.pivot.related_section}
                                    />

                                    <Label
                                        string={op.name}
                                        for={op.name}
                                        style={{ fontWeight: "normal", fontSize: "15px", top: "-2px", right: "-5px", position: "relative", marginTop: 2, fontFamily: 'Montserrat', }}>
                                        {op.name}
                                    </Label>
                                </div>
                            )}

                        </FormGroup>
                    </>
                )
            } else if (field.input_type.type === 'file') {
                return (
                    <>
                        <FormGroup id='input_div'>
                            <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "17px", color: '#0d6efd', fontFamily: 'Montserrat', }} string={field.id} for={field.id}>{field.name}{field.answer_required && <span style={{ color: "red" }}>(Requis)</span>}</label><br />
                            <input
                                className='form-control'
                                style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", width: "100%", height: "50px", padding: "0px 15px", fontFamily: 'Montserrat', minWidth: "200px", }}
                                type={field.input_type.type}
                                name={field.id}
                                id={field.id}
                                multiple={true}
                                required={field.answer_required}
                                onChange={(e) => {
                                    fileInputsData = [];
                                    for (let index = 0; index < e.target.files.length; index++) {
                                        const file = e.target.files[index];
                                        readBlob(file);
                                    }
                                }} />
                            <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                                Champ invalide !!!
                            </div>
                        </FormGroup>
                    </>
                )
            } else {
                return (
                    <>
                        <FormGroup id='input_div'>
                            <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "17px", color: '#0d6efd', fontFamily: 'Montserrat', }} string={field.id} for={field.id}>{field.question}{field.answer_required && <span style={{ color: "red" }}>(Requis)</span>}</label><br />
                            <input
                                className='form-control'
                                style={{ border: "1.5px solid #0d6efd", borderRadius: "5px", width: "100%", height: "50px", padding: "0px 15px", fontFamily: 'Montserrat', minWidth: "200px", }}
                                type={field.input_type.type}
                                name={field.id}
                                id={field.id}
                                required={field.answer_required}
                                onChange={(e) => {
                                    updateValue({
                                        answer_text: e.target.value,
                                        // choice: {'group': field.option_group_id, 'value': null},
                                        choice: field.option_choices[0].id,
                                        question: field.id
                                    })
                                }} />
                            <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                                Champ invalide !!!
                            </div>
                        </FormGroup>
                    </>
                )
            }
        }
        // End Field
    }


    useEffect(() => {
        if (localStorage.getItem("user_token") === "" || localStorage.getItem("user_token") === null) {
            localStorage.setItem("redirect_url", window.location.pathname);
            window.location = "/auth";
        }
    }, [])

    const handleSubmit = async (e) => {
        if (fields?.length) {
            e.preventDefault();
        }
        // console.log("FORM DATA", formData);
        const dataToSend = {

        }
        let responseSubmit = await submitAdditionalInfo(query.id, formData);
        
        // console.log("responseSubmit ", responseSubmit)
        if (responseSubmit.data?.success) {
            toast.success("Formulaire soumis avec succès !!!");
            setQuery({ ...query, status: "submitted" })
        } else {
            toast.error("Une erreur est survenue !!!");
        }

    }
    return (
        <div className={"query"} id={"query"} style={{ paddingTop: "40px", paddingBottom: "40px", width: "80%", margin: "auto", fontFamily: 'Montserrat' }}>
            {/* Title */}
            <h4 className={'page-title-query'} style={{ background: "#4385f6 0% 0% no-repeat padding-box", paddingTop: "40px", paddingBottom: "40px", borderRadius: "5px", }}>VÉRIFIER LE STATUT D'UNE DEMANDE</h4>


            {/* "Check status" input */}
            <form onSubmit={loadQueryData} style={{ background: "#ffffff", paddingTop: "40px", paddingBottom: "40px", paddingRight: "10%", paddingLeft: "10%", borderRadius: "5px", textAlign: "left", }}>
                <p style={{ color: "gray", fontWeight: "bold", fontSize: "20px", }}>Veuillez renseigner le numéro de référence de votre demande</p>
                <p style={{ color: "gray" }}>Rechercher par :</p>
                <div style={{ color: "black" }}>
                    <input type="radio" id="ref_number" name="ref_number" value="ref_number" checked />
                    <label style={{ fontWeight: "normal", fontSize: "12px", top: "-2px", right: "-5px", position: "relative", }} for="ref_number">Référence de la demande</label>
                </div>
                <div id='input_div'>
                    <label style={{ fontWeight: "normal", top: "15px", right: "-10px", position: "relative", backgroundColor: "white", padding: "0px 2px 0px 2px", fontSize: "12px", }} for="ref_number">Référence de la demande</label><br />
                    <input className='form-control' style={{ border: "1.5px solid black", borderRadius: "5px", width: "50%", height: "50px", padding: "0px 15px", minWidth: "200px", }} type="text" id="ref_number_input" name="ref_number_input" required />
                    <div className="invalid-feedback" style={{ padding: "0px 15px", }}>
                        Numéro de référence invalide!!!
                    </div>
                </div>
                <button type='submit' style={{ marginTop: "50px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "darkorange", color: "white", fontWeight: "bold", }}>Vérifier</button>
            </form>

            {/* Status result */}
            {query.status ? <div>
                <h4 style={{ paddingTop: "40px", paddingBottom: "40px" }}>Demande N° {query.id}</h4>
                <div style={{ backgroundColor: "rgb(225, 225, 225)", padding: "40px", borderRadius: "15px", }}>
                    <h4 style={{ paddingTop: "40px", paddingBottom: "40px" }}>{query.label}</h4>
                    {query.status === "submitted" ? <p style={{ padding: "40px 0px", }}>Statut: <b style={{ color: "#FFBE00" }}>SOUMIS</b> </p> : null}
                    {query.status === "rejected" ? <p style={{ padding: "40px 0px", }}>Statut: <b style={{ color: "red" }}>REJETE</b> </p> : null}
                    {query.status === "pending" ? <p style={{ padding: "40px 0px", }}>Statut: <b style={{ color: "#4385f6" }}>EN ATTENTE</b> </p> : null}
                    {query.status === "approved" ? <p style={{ padding: "40px 0px", }}>Statut: <b style={{ color: "green" }}>APPROUVE</b> </p> : null}
                    {query.status === "additional_info" ? <p style={{ padding: "40px 0px", }}>Statut: <b style={{ color: "orange" }}>INCOMPLET</b> </p> : null}


                    {query.status === "submitted" ? <p style={{ padding: "40px 0px", }}>Votre demande a été soumise et est en cours de validation.</p> : null}
                    {query.status === "rejected" ? <p style={{ padding: "40px 0px", }}>Votre demande a été rejetée.</p> : null}
                    {query.status === "approved" ? <p style={{ padding: "40px 0px", }}>Votre demande a été approuvée.</p> : null}
                    {query.status === "pending" ? <p style={{ padding: "40px 0px", }}>Votre demande est en attente.</p> : null}
                    {query.status === "additional_info" ?
                        <>
                            <p style={{ padding: "40px 0px", }}>Votre demande est imcomplète. Veuillez soumettre les informations complémentaires indispensable au traitement.</p>
                            <div className="w-100 d-flex justify-content-center">
                                {fields?.length ? fields?.map((field, index) =>
                                    <div className="col-sm-6 col-xs-12 d-flex justify-content-center py-3">
                                        <CustomField key={field.id} field={field} updateValue={updateValue} />
                                    </div>

                                ) : null}
                            </div>
                            <div className="w-100 d-flex justify-content-center">

                                <div id='button-next' className="col-sm-6 col-xs-12 d-flex justify-content-center py-3">
                                    <Button style={{ cursor: "pointer", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#093d62", color: "white", fontWeight: "bold", }}
                                        className="auth-form-btn"
                                        onClick={(e) => {
                                            handleSubmit(e)
                                        }}>
                                        Soumettre
                                    </Button></div>
                            </div>
                        </>
                        : null}
                </div>
            </div> : <div></div>
            }

        </div>
    )
}

export default StatutDemande;
