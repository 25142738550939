import React, { useEffect, useState } from 'react'
import "./ForumsDiscussionTemplate.css";
import img5 from "../../assets/images/img5.jpg";
import presi_apdp from "../../assets/images/President_apdp.png";
import FaqSection from '../../components/FaqSection/FaqSection';
import ActualiteCarousel from '../../components/ActualiteCarousel/ActualiteCarousel';
import {Carousel, Card, Figure, Button, ButtonGroup, Accordion} from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import {BiCalendarCheck} from "react-icons/bi"
import imgCard5 from "../../assets/images/img5.jpg"
import imgCard6 from "../../assets/images/img6.jpg"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { getATypeOfArticles, getForums } from '../../http/http';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import TemplateActualityListListCard from '../../components/TemplateActualityListCard/TemplateActualityListCard';
import HeroSection from '../../components/HeroSection/HeroSection';
import ForumDiscussionRow from '../../components/ForumDiscussionRow/ForumDiscussionRow';

const ForumsDiscussionTemplate = () => {

    const { menu } = useParams()

    const navigate = useNavigate()

    const [articles, setArticles] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)




    const changePage = ({ selected }) => {
        let currentPage = selected + 1;
        setPageNumber(currentPage)
        // console.log("ok ", selected + 1)
    }


    useEffect(() => {
        loadArticlesData();
        // console.log("Articles data load", articles)
    }, [pageNumber, menu])


    const defaultData = [
        {
            id: "1",
            subject: "Sujet de prédilection des users",
            date: "12-09-2022",
            description: "Occaecat labore id do labore ipsum qui laboris sint minim voluptate do nulla aliqua consectetur.",
            comments: [
                {
                    user: "John DOE",
                    content: "Okay, alors continuons d'implémenter ces fonctionnalités pour vite en finir",
            },
                {
                    user: "Jane DOE",
                    content: "Here we are to work and try to get this thing figured out",
            },
                {
                    user: "Paul NEWTON",
                    content: "This is not what you think about",
            },
                {
                    user: "Capelo KITRICH",
                    content: "Nous pensons que nos droits à l'image devraient être mieux gérés",
            },
            ]
    },
        {
            id: "2",
            subject: "Les droits des utilisateurs",
            date: "17-07-2022",
            description: "Nulla esse sint aliquip elit culpa qui non nisi excepteur aute commodo sit magna.",
            comments: [
                {
                    user: "John DOE",
                    content: "Okay, alors continuons d'implémenter ces fonctionnalités pour vite en finir",
            },
                {
                    user: "Jane DOE",
                    content: "Here we are to work and try to get this thing figured out",
            },
                {
                    user: "Paul NEWTON",
                    content: "This is not what you think about",
            },
                {
                    user: "Capelo KITRICH",
                    content: "Nous pensons que nos droits à l'image devraient être mieux gérés",
            },
            ]
    },
        {
            id: "3",
            subject: "La liberté d'expression",
            description: "Sit velit aliquip sint nulla adipisicing eiusmod.",
            date: "12-01-2022",
            comments: [
                {
                    user: "John DOE",
                    content: "Okay, alors continuons d'implémenter ces fonctionnalités pour vite en finir",
            },
                {
                    user: "Jane DOE",
                    content: "Here we are to work and try to get this thing figured out",
            },
                {
                    user: "Paul NEWTON",
                    content: "This is not what you think about",
            },
                {
                    user: "Capelo KITRICH",
                    content: "Nous pensons que nos droits à l'image devraient être mieux gérés",
            },
            ]
    },
    ]




    const loadArticlesData = async () => {
        // console.log("subMenu load", menu)
        const resp = await getForums(pageNumber);
        if (resp.response && resp.response.status !== 200) {
          // console.log("data error ", resp.response)
        } else {
          // console.log("data data load", resp.data)
          const perPageValue = resp?.data?.meta?.per_page
          setPerPage(perPageValue)
          const total = resp?.data?.meta?.total;
          setTotalPage(Math.ceil(total / perPageValue))
    
          setArticles(resp.data.data)
          setIsLoading(false)
        }
    
    
      }

      return (<div className='pb-5' >
      { !isLoading ?
        <>
          <HeroSection />
          <Breadcrumb>
            <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item href="#">Forums de discussions</Breadcrumb.Item>
          </Breadcrumb>
          <div className="">
            {articles.length !== 0 ? articles.map((item, index) => <div className='' key={index}><ForumDiscussionRow 
            id={item.id}
            title={item.topic} 
            comments={item.comments_count} 
            date={item.created_at} 
            decription={item.body}
             
              />
            </div>) : <h1 className='m-auto p-5'>Aucune Donnée</h1>}
          </div>
          {articles && articles?.length !== 0 && <Pagination
            changePage={changePage}
            pageCount={totalPage}
            perPage={perPage}
          />}

        </>
        :
        <Loader /> }
    </div>
  )
}

export default ForumsDiscussionTemplate