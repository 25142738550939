import React, { useState } from 'react';
import "./FaireUneDemandeCard.css";
import { HiLink } from 'react-icons/hi';

const FaireUneDemandeCard = (props) => {
    const {title, description, handleCardClicked} = props;
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
        <div 
            id="faire-une-demande-card" 
            className="faire-une-demande-card d-flex" 
            onClick={handleCardClicked}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="icon-container d-flex justify-content-space-evenly">
                <HiLink className="icon" />
            </div>
            <div 
                className="text-container"
                style={{  
                    display: 'inline-block'
                }}
                >
                    <p
                        className="title" 
                        style={{ 
                            overflow: isHovered ? 'visible' : 'hidden',
                            textOverflow: 'ellipsis', 
                            whiteSpace: isHovered ? 'normal' : 'nowrap',
                            marginBottom: 0
                        }}
                    >
                        {title}
                    </p>
                    <p
                        className="description"
                        style={{
                            margin: 0,
                            overflow: isHovered ? 'visible' : 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: isHovered ? 'normal' : 'nowrap',
                        }}
                    >
                        {description}
                    </p>
                </div>
            {/* <div className="text-container">
            <p className="title">{title}</p>
            <p className="description">{description}</p>
            </div> */}
        </div>
    )
}

export default FaireUneDemandeCard
