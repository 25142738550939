import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Container, Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import apdp_logo from "../../assets/images/logoapdp.svg"
import NavBar from './NavBar';
import './Header.css';
import code_numerique from "../../assets/documents/CODE_DU_NUMERIQUE_DU BENIN_EDITION 2019_ADN.pdf";
import { getATypeOfArticles, logout } from '../../http/http';
import { useNavigate } from 'react-router-dom';
import ActualiteCarousel from '../../components/ActualiteCarousel/ActualiteCarousel';



const Header = (props) => {


    const { firstMenus, secondMenus } = props;

    const [isOpen, setIsOpen] = useState(false)

    const [breaknews, setBreaknews] = useState([]);
    const [showDropdownApdp, setShowDropdownApdp] = useState(false);

    const navigate = useNavigate()

    const [state, setState] = useState(
        {
            showSub: "",
            showSubSub: ""
        }
    )

    const [activeMenu, setActiveMenu] = useState("")



    const handleViewBreaknews = (id) => {
        window.location.replace(`/breaknews/${id}`)
    }

    const loadBreaknewsData = async () => {
        const resp = await getATypeOfArticles("breaknews")
        if (resp.response && resp.response.status !== 200) {
             console.log("data error ", resp.response)
        } else {
            setBreaknews(resp.data?.data.reverse())
        }
    }


    useEffect(() => {
        loadBreaknewsData();
    }, [])

    const handleLocalStorage = () => {
        localStorage.setItem("active-menu", JSON.stringify({"id":63,"name":"Codes du Numérique","slug":"codes_du_numerique","level":2,"model":"documentations","parent_id":7,"created_at":"2023-07-13T22:30:53.000000Z","updated_at":"2023-07-13T22:50:28.000000Z","concerned_resource":"documentations","concerned_article_type":47,"children":[]}))
    }

    const toggle_modal = () => {
        setIsOpen(!isOpen)
    }
    const goHome = () => {
        window.location.replace("/");
    }

    const handleClickApdpInfo = () => {
        window.location.replace("/mission");
    }

    const handleClickRecommandations = () => {
        window.location.replace("/recommandations");
    }

    const session_logout = () => {
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_token');
        localStorage.removeItem('is_dpo');
        localStorage.removeItem('is_rt');
        window.location = '/';
    }



    return (
        <>
            <React.Fragment>
                <div id={"header"}>
                    <div className="hwrap">
                        <div className="hmove">
                            {
                                breaknews && breaknews?.map((item, index) =>
                                (
                                    <p key={index + "w"} onClick={() => handleViewBreaknews(item.id)}
                                         style={{ color: '#fff' }}
                                        className="hitem  mx-5 ql-editor"
                                        dangerouslySetInnerHTML={{ __html: (item?.content) }}
                                        >
                                            {/* {item?.title} : {item?.content} */}
                                        </p>
                                )
                                )}

                        </div>
                    </div>

                    <div className="header-block-2">


                        <div className='header-logo' onClick={goHome} style={{ backgroundImage: `url('${apdp_logo}'`, cursor: "pointer" }}>
                            {/* <img src={apdp_logo} style={{cursor: "pointer"}} className="logo" /> */}
                            {/* <object style={{cursor: "pointer", height: 30, width: 40}}  data={apdp_logo} className="logo" type="image/svg+xml"></object> */}
                        </div>
                        <div className="d-md-flex flex-wrap header-block-2-div-2 d-flex flex-row justify-content-around">
                            {firstMenus?.sort((a, b) => (a.order > b.order) ? 1 : -1).map((sub, index) =>
                                <>{sub.children?.length === 0 ?
                                    <div
                                        key={index + 'fmenus'}
                                        className="col"
                                        style={{ color: "#292929", font: "normal normal normal 20px/35px Montserrat", cursor: "pointer", width: 'fit-content', flex: 'none', padding: '2px 10px' }}
                                        onClick={() => {
                                            localStorage.setItem('active-menu', JSON.stringify(sub))
                                            navigate(`/${sub?.model}/${sub?.slug}`)
                                        }
                                        }
                                    > 
                                    {sub.name.toUpperCase()}
                                    </div> :
                                    <div className='dropdown'
                                        onMouseLeave={() => setState({ ...state, showSub: "" })}
                                        onMouseOver={() => setState({ ...state, showSub: sub.name })}
                                    >
                                        <div className="dropdown-toggle drop-class" style={{ font: "normal normal normal 20px/35px Montserrat", cursor: "pointer", width: 'fit-content', flex: 'none', padding: '2px 10px' }}

                                            type="" data-toggle="dropdown">{sub.name.toUpperCase()}
                                        </div>
                                        <ul style={{width: 'fit-content', paddingRight: '8px'}} className={state.showSub == sub.name ? "dropdown-menu show pop-up" : "dropdown-menu"}>
                                            {sub.children?.sort((a, b) => (a.order > b.order) ? 1 : -1).map((subItem, index) =>
                                                <li key={index} style={{ cursor: 'pointer' }}><a tabIndex="-1" onClick={() => {
                                                    setActiveMenu(subItem.parent_id)
                                                    localStorage.setItem('active-menu', JSON.stringify(sub))
                                                    navigate(`/${subItem?.model}/${subItem?.slug}`)
                                                }}>{subItem.name}</a></li>)}

                                        </ul>
                                    </div>}
                                </>
                            )}
                        </div>

                        {localStorage.getItem("user_token") ? <div>
                            <Button color="danger" style={{ margin: '20px' }} onClick={session_logout} className="client-espace-button"><h6 style={{ margin: '0px' }}>Déconnexion</h6></Button>
                        </div> : <div></div>}
                    </div>

                    <div className="header-block-3">
                        <NavBar secondMenus={secondMenus.sort((a, b) => (a.order > b.order) ? 1 : -1)} />
                    </div>
                </div>
            </React.Fragment>
        </>
    )
}

export default Header
