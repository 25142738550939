import React, { useEffect, useLayoutEffect, useState } from 'react'
import "./Auth.css"
import signin from "../../assets/icons/signin.svg"
import signup from "../../assets/icons/signup.svg"
import {ImMail3} from "react-icons/im"
import {FiKey} from "react-icons/fi"
import {IoMdEye} from "react-icons/io"
import {IoMdEyeOff} from "react-icons/io"
import {BsArrowRightShort} from "react-icons/bs"
import {FaUser} from "react-icons/fa"
import {register_or_login, get_user, reinitPassword} from '../../http/http';
import { ToastContainer, toast } from 'react-toastify';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';



const SignIn = () => {

    const [connexion, setConnexion] = useState(true);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);
    const [passwordForgottenEmail, setPasswordForgottenEmail] = useState("");

    const handleAuthOperation = () => { 
        setConnexion(!connexion);     
    }

    // console.log(document.cookie)

    const handlePasswordVisibility = () => {
        setPasswordIsVisible(!passwordIsVisible);
    }

  
    const submit = async (e) => {
        e.preventDefault();
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha_value, false)==true){

        const data = connexion ? {
            email: email,
            password: password,
        } :{
            name: username,
            email: email,
            password: password,
            password_confirmation: password,
        }
        const resp = await register_or_login(connexion ? "login" :"register", data)
        
        
         try {
            if (resp?.data?.success){
                if (connexion) {
                    toast.success("Authentification réussie !!!");
                    localStorage.setItem("user_token", resp.data.data.token);
                } else {
                    toast.success("Inscription réussie. Veuillez vérifier votre boîte mail pour validation.");
                    localStorage.setItem("user_token", resp.data.data.token);
                }
                const user = await get_user(localStorage.getItem("user_token"));
                if (user.data.id){
                    toast.success(`Bienvenue ${user.data.name}`);
                    localStorage.setItem("user_id",user.data.id);
                    localStorage.setItem("user_name",user.data.name);
                    localStorage.setItem("user_email",user.data.email);
                    localStorage.setItem("is_dpo", false);
                    for (let index = 0; index < user.data.roles.length; index++) {
                        const role = user.data.roles[index];
                        if (String(role?.slug).toLowerCase().includes("dpo")){
                            localStorage.setItem("is_dpo",true);
                        }
                        if (String(role?.slug).toLowerCase().includes("rt")){
                            localStorage.setItem("is_rt",true);
                        }
                    }
                    setTimeout(() => {
                        if (localStorage.getItem("redirect_url") !== null){
                            window.location = localStorage.getItem("redirect_url");
                        } else {
                            window.location = '/';
                        }
                    }, 3000);
                } else {
                    localStorage.clear()
                };
            } else {    
          toast.error(resp?.response?.data?.message);


            }
         } catch (error) {
             toast.error(error)
            //  if (connexion) {
            //      toast.error("Email ou mot de passe incorrect !");  
            //  } else {
            //      toast.error("Email ou pseudo déjà existant !");
            //  }
         };
        } else {
            toast.error("Captcha non valide. Veuilez réessayer.");
        }

    }

    useLayoutEffect(() => {
        if (localStorage.getItem("user_token") !== null){
            if (localStorage.getItem("redirect_url") !== null){
                window.location = localStorage.getItem("redirect_url");
            } else {
                window.location = '/';
            }
        }
        loadCaptchaEnginge(7);
    }, [])
    

    const reinitializePassword = async () => {
        const resp = await reinitPassword(passwordForgottenEmail)
        
         try {
            if (resp?.data?.success){
                    toast.success("Veuillez consulter votre boîte mail !!!");
                    setEmail('');
                    setIsPasswordForgotten(false)
            } else {
                console.log(resp)
                toast.error(resp?.response?.data?.message);
            }
         } catch (error) {
                toast.error(error)
            
         };
    }
    
    return (
        <form name='authentication_form' onSubmit={submit}>
            <div className={"auth"}>

                <ToastContainer />

                <div className={"auth-intern-card row d-flex flex-wrap"}>
                    <div className={"auth-illustrartion-card col"}>
                    {connexion === true ? <img src={signin} alt="sign-in" className={"auth-illlustration-icon"}/> : <img src={signup} alt="signup" className={"auth-illlustration-icon"}/>}
                        
                    </div>
                    <div className={"auth-form-card col"}>
                        
                    {!isPasswordForgotten ? 
                    <>
                        <p className={"auth-title"}>{connexion === true ? "Connexion" : "Inscription"}</p>
                        { (connexion === false && !isPasswordForgotten) ? <div className={"auth-form-input-container"}>
                            <FaUser className={"auth-form-input-icon"}/>
                            <input onChange={(e) => setUsername(e.target.value)} placeholder={"Entrer votre pseudo"} className={"auth-form-input"} title="Ce champ est requis..." required/>
                        </div> : null }

                        <div className={"auth-form-input-container"}>
                            <ImMail3 className={"auth-form-input-icon"}/>
                            <input onChange={(e) => setEmail(e.target.value)} placeholder={"Entrer votre mail"} className={"auth-form-input"} type="email" required />
                        </div>
                        <div className={"auth-form-input-container d-flex flex-nowrap "}>
                            <FiKey className={"auth-form-input-icon key"}/>
                            <input onChange={(e) => setPassword(e.target.value)} placeholder={"Entrer votre mot de passe"} type={passwordIsVisible === false ? "password" : "text"} className={"auth-form-input"} minLength="8"  required/>
                        { passwordIsVisible === false ?
                        <IoMdEye className={"auth-form-input-pwd-eye"} onClick={handlePasswordVisibility}/> : 
                            <IoMdEyeOff className={"auth-form-input-pwd-eye"} onClick={handlePasswordVisibility}/>
                        } 
                        </div>
                        <div className='w-100 text-center'>
                            {/* <label for="captcha">Captcha</label> */}
                            <div className='d-flex my-5 justify-content-evenly'>
                                <div className=''>
                                    <LoadCanvasTemplate reloadText="Recharger Captcha" />
                                </div>
                            <input className={"auth-captcha-input"} type="text" name="" id="user_captcha_input" placeholder='Saisir le code Captcha' />
                            </div>
                            
                            </div>


                        {connexion === false ? <div className={"auth-form-under-link"}>
                            <input className={"auth-form-checkbox"} type="checkbox" required/>
                            <span className={"auth-form-checkbox-first-text"}>J'ai lu et j'accepte </span>
                            <span className={"auth-form-checkbox-second-text"}>les contions générales d'utilisation.</span>
                        </div> : null }

                        <div className={"auth-form-under-link row d-flex justify-content-between"}>

                        <div className={"col-7 auth-form-password-forgotten"} onClick={() => setIsPasswordForgotten(true)}>{ connexion === true ? "Mot de passe oublié ?" : ""}</div>
                        {/* <div className={"col-5 auth-form-other-option d-flex flex-nowrap justify-content-end"} onClick={handleAuthOperation}>{ connexion === true ? "S'inscrire" : "Se Connecter"}</div> */}
                        </div>
                        <button type='submit' className={"auth-form-btn"}>{ connexion === false ? "S'inscrire" : "Se Connecter"}<BsArrowRightShort className={"auth-form-btn-arrow-icon"}/></button>

                        <p className={"mb-3"}>
                            <span className={"auth-form-checkbox-first-text"}>{ connexion === true ? "Vous n'avez pas encore un compte ?" : "Vous avez déjà un compte ?"}</span>
                            <span className={"auth-form-other-option "} onClick={handleAuthOperation}>{ connexion === true ? " S'inscrire" : " Se Connecter"}</span>
                        </p>
</> :
<>
                        <p className={"auth-title"}>Mot de passe oublié</p>
                        <div className={"auth-form-input-container"}>
                            <ImMail3 className={"auth-form-input-icon"}/>
                            <input value={passwordForgottenEmail} onChange={(e) => setPasswordForgottenEmail(e.target.value)} placeholder={"Entrer votre mail"} className={"auth-form-input"} type="email" required />
                        </div>
                        <button type='button' onClick={reinitializePassword} className={"auth-form-btn"}>Réinitialiser<BsArrowRightShort className={"auth-form-btn-arrow-icon"}/></button>

                        </>
                        }
                    </div>

                </div>
            </div>
        </form>
    )
}

export default SignIn
