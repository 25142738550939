/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import "./GlobalResearch.css";
import { Card, Button, Tab, Row, Form, InputGroup } from "react-bootstrap";
import {  getAllArticleTypes, getArticlesResearched } from '../../http/http';
import Pagination from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';


const GlobalResearch = () => {
  const navigate = useNavigate()

  const [articlesResearched, setArticlesResearched] = useState([]);
  const [searchText, setSearchText] = useState("empty");
  const [perPage, setPerPage] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [year, setYear] = useState("");
  const [years, setYears] = useState([]);



  const changePage = ({ selected }) => {
    let currentPage = selected + 1;
    setPageNumber(currentPage)
    // console.log("ok ", selected + 1)
  }
  useEffect(() => {
    if (searchText !== "empty") {
      loadResultsData(1, searchText, type, year)
      loadTypesAndYearsData();

      // console.log("Recherche data searchText changed", articlesResearched)
    } else {
      loadResultsData(1, "", type, year);
      loadTypesAndYearsData();
    }
  }, [searchText, year, type])

  useEffect(() => {
    if (searchText === "empty") {

      loadResultsData(pageNumber, "", type, year);
      loadTypesAndYearsData();

    } else {
      loadResultsData(pageNumber, searchText, type, year)
      loadTypesAndYearsData();


    }
    // console.log("Recherche data page Number change", articlesResearched)
  }, [pageNumber])

  const loadResultsData = async (page, text, type, year) => {
    const response = await getArticlesResearched(text, page, type, year)
    if (response.response && response.response.status !== 200) {
      // console.log("Error Searched", response.response)
    } else {
      // console.log("Data Searched", response)
      const perPageValue = response?.data?.per_page
      setPerPage(perPageValue)
      const total = response?.data?.total;
      setTotalPage(Math.ceil(total / perPageValue))
      setArticlesResearched(response.data?.data)
    }
  }
  const loadTypesAndYearsData = async () => {
    const response = await getAllArticleTypes()
    if (response.response && response.response.status !== 200) {
      console.log("Error Searched", response.response)
    } else {
      console.log("Data Searched", response);
      setTypes(response?.data?.documentations);
      setYears(Object.values(response?.data?.years));

      
    }
  }


  const handleSearch = (e) => {
    // console.log(e.target.value)
    if (e.target.value === "") {
      setSearchText("empty")
    } else {
      setSearchText(e.target.value)
    }
  }

  return (
    <div style={{ background: '#E2E2E2', paddingTop: "40px", paddingBottom: "40px" }}>
      {/* <ActualiteCarousel/> */}
      {/* <Breadcrumb>
          <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Documentation</Breadcrumb.Item>
          <Breadcrumb.Item href="#">Textes Nationaux</Breadcrumb.Item>
          <Breadcrumb.Item active>Décisions publiques</Breadcrumb.Item>
      </Breadcrumb> */}
      <div className={'deliberations-div'}>
        <h3>Rechercher une information</h3>

        <Tab.Container id="top-tabs-example" defaultActiveKey="first">
          <Row>
            <div className={'row'}>
              {/* <Nav variant="pills" className="flex-row">
                  <Nav.Item style={{cursor: "pointer"}}>
                  <Nav.Link eventKey="first"> <GiInjustice/> ANNEE 2022</Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={{cursor: "pointer"}}>
                  <Nav.Link eventKey="second"> <GiInjustice/> ANNEE 2021</Nav.Link>
                  </Nav.Item>
                </Nav> */}
            </div>
            <Row>
<div className='row'>
  <div className="col-sm-4 col-xs-12">
  <InputGroup className='d-flex flex-nowrap w-100 search-input-group'>
                <Form.Control value={searchText === "empty" ? "" : searchText} onChange={(e) => handleSearch(e)} type="text" placeholder="Rechercher..." className='deliberation-search-input ' />
                <InputGroup.Text>

                  <BiSearchAlt title='Rechercher globalement sur le site' style={{ height: 40, width: 40, color: 'black' }} />
                </InputGroup.Text>

              </InputGroup>
  </div>
  <div className="col-sm-4 col-xs-12">
  <div className="flex-nowrap">
                    <select onChange={(e) => setType(e.target.value)} name='thematic' id="" className='w-100 search-select' >
                        <option value="" disabled="disabled" selected="true">Choisir Type</option>
                        <option value="">Tous</option> 
                       {types.map(item => <option key={item.slug} value={item.slug}>{item.name}</option>)}
                    </select>
                </div>
  </div>
  <div className="col-sm-4 col-xs-12">
  <div className="flex-nowrap">
                    <select onChange={(e) => setYear(e.target.value)} name='thematic' id="" className='w-100 search-select' >
                        <option value="" disabled="disabled" selected="true">Choisir Année</option>
                        <option value="">Toutes</option> 
                       {years.map(item => <option key={item} value={item}>{item}</option>)}
                    </select>
                </div>
  </div>

              
</div>
              {/* <Tab.Content> */}
                {/* <Tab.Pane eventKey="first"> */}
                  {/* <h3 style={{ 'text-transform': 'uppercase', 'text-align': 'left', marginBottom: "30px"}}>DéLIBéRATIONS 2022 (nombre: 04)</h3> */}
                  <div className={'pane-cards mb-5'}>
                    {articlesResearched?.length !== 0 ? articlesResearched
                      .map((result, index) =>
                        <Card key={index + "w"} style={{ 'text-align': 'left', }}>
                          <Card.Body>
                            <Card.Title style={{ 'font-weight': 'bold', }}>{result.title}</Card.Title>
                            <Card.Text style={{ 'margin-top': '30px', 'margin-bottom': '30px' }}>
                              {result.sub_title}
                              {/* <div className='ql-editor' dangerouslySetInnerHTML={{ __html: (result?.content) }}></div> */}
                            </Card.Text>
                            {/* <a href={result.image_url} download> */}
                            <Button variant="outline-primary" onClick={() => navigate(`/recherche-globale/${result.id}`)} style={{ 'float': 'right' }}> Lire plus</Button>
                            {/* </a> */}
                          </Card.Body>
                        </Card>
                      ) : <h1> Aucun résultat </h1>
                    }


                  </div>
                  {articlesResearched && articlesResearched?.length !== 0 && <Pagination
                    changePage={changePage}
                    pageCount={totalPage}
                    perPage={perPage}
                  />}
                {/* </Tab.Pane> */}

              {/* </Tab.Content> */}
            </Row>
          </Row>
        </Tab.Container>
      </div>
    </div >
  )
}

export default GlobalResearch;
