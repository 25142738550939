import React, {useState, useEffect} from 'react';
import CustomInput from '../../components/CustomInput/CustomInput';
import {Form, Button} from 'reactstrap';
import {ImSad, ImHappy} from "react-icons/im";
import {RiRefreshLine } from "react-icons/ri";
import './Dynamique.css';
import {getForm, submitFormSection, getRequestedQuery, getSectionFormAnswers, setLocalStorageInfos, getLocalStorageInfos, getMediaFile, submitEntireForm} from '../../http/http';
import { ToastContainer, toast } from 'react-toastify';
import { Stepper, Step } from 'react-form-stepper';
import { ButtonGroup } from 'react-bootstrap';

export default function DynamiqueForm() {
    const [formData, setFormData] = useState({
        answers: [],
        user: localStorage.getItem("user_id")
    });
    const [current, setCurrent] = useState(0);
    const [showSubmit, setShowSubmit] = useState(false);
    const [responseCounter, setResponseCounter] = useState(0);
    const [formulaire, setFormulaire] = useState(null);
    const [standardSections, setStandardSections] = useState([]);
    const [allSections, setAllSections] = useState(null);
    const [annexesSections, setAnnexesSections] = useState([]);
    const [pjSections, setPjSections] = useState([]);
    const [refNumber, setRefNumber] = useState("");
    const [movement, setMovement] = useState("forward");
    const [query, setQuery] = useState({});
    const [answersForm, setAnswersForm] = useState(null);
    const [loadingResponses, setLoadingResponses] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const delay = ms => new Promise(res => setTimeout(res, ms));
    let queryAttemptsCounter = 0;
    let signatures = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        R0lGODlh: "image/gif",
        iVBORw0KGgo: "image/png",
        "/9j/": "image/jpg"
    };
    let standardBackgroundColor;
    let annexeBackgroundColor;
    let pjBackgroundColor;

    function downloadMultipleFiles(links) {
        links.forEach(link => {
            const anchor = document.createElement('a');
            anchor.href = link.url;
            anchor.download = link.fileName;
            document.body.appendChild(anchor);

            // Simulez un clic pour déclencher le téléchargement
            anchor.click();

            // Retirez l'élément du DOM après le téléchargement
            document.body.removeChild(anchor);
        });
    }

    async function getSectionAnswers(sectionId, submissionId) {
        setLoadingResponses(true);
        if ( submissionId != null && submissionId != undefined && sectionId != null && sectionId != undefined ) {
            const responses = await getSectionFormAnswers(submissionId, sectionId);

            // Sélectionnez tous les éléments avec la classe "col-sm-6"
            const colSm6Elements = document.querySelectorAll('.col-sm-6');

            const allInputElements = [];

            // Parcourez les éléments et récupérez les valeurs des attributs "string" des labels
            colSm6Elements.forEach((element) => {
                // Utilisez querySelectorAll pour récupérer tous les éléments input dans cet élément col-sm-6
                const inputElements = element.querySelectorAll('input, textarea');

                // Ajoutez les éléments input au tableau global
                allInputElements.push(...inputElements);
            });
            
            for (let i = 0; i < responses?.data?.data?.answers?.length; i++) {
                const response = responses?.data?.data?.answers[i];
                var checkboxes = [];
                for (let j = 0; j < allInputElements.length; j++) {
                    const input = allInputElements[j];
                    if (input.getAttribute('type') == 'radio'){
                        if (input.getAttribute('data-question') == response?.question_id && input.getAttribute('value') == response?.answer?.answer_text){
                            input.checked = true;
                            updateFormData({
                                answer_text: input?.value,
                                choice: parseInt(input?.id),
                                question: parseInt(input.getAttribute('data-question'))
                            });
                        }
                    } else if (input.getAttribute('type') == 'checkbox') {
                        if (input.getAttribute('data-question') == response?.question_id && response?.answer?.answer_text?.split(";").map(element => element.trim()).includes(input.getAttribute('value').replaceAll(';',''))){
                            input.checked = true;
                            checkboxes.push(input?.value);
                            updateFormData({
                                answer_text: checkboxes.join('; '),
                                choice: parseInt(input.getAttribute('data-choice')),
                                question: parseInt(input.getAttribute('data-question'))
                            })
                        }
                    } else if (input.getAttribute('type') == 'file') {
                        // console.log('Do nothing');
                    } else {
                        if (input.getAttribute('id') == response?.question_id){
                            input.value = "";
                            if (response?.answer?.answer_text && response?.answer?.answer_text != "undefined") {
                                input.value = response?.answer?.answer_text;
                            }
                            if (input.value) {
                                updateFormData({
                                    answer_text: input.value,
                                    choice: parseInt(input.getAttribute('data-choice')),
                                    question: parseInt(input.id)
                                })
                            }
                        }
                    }
                }
            }
        }
        setLoadingResponses(false);
    }

    function detectMimeType(b64) {
        for (var s in signatures) {
            if (b64.indexOf(s) === 0) {
            return signatures[s];
            }
        }
    }

	async function sendFormSubmissionInfos(form) {
		if (form && form.data){
			const data = {
				'temp_data': {}
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_standard_section`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_standard_section`) !== undefined) {
				data['temp_data']['last_standard_section'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_standard_section`)
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`) !== undefined) {
				data['temp_data']['last_section_submitted'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`)
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`) !== undefined) {
				data['temp_data']['preview'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`)
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`) !== undefined) {
				data['temp_data']['filledSections'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`)
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) !== undefined) {
				data['temp_data']['final'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`)
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`) !== undefined) {
				data['temp_data']['saved_num'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`)
			}

			if (localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`) !== null && localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`) !== undefined) {
				data['temp_data']['submit_id'] = localStorage.getItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`)
			}
            try {
                const sessionInfos = await setLocalStorageInfos(form.data.type.id, data);   
            } catch (error) {
            }
		}
    }

	async function setFormSubmissionInfos(form) {
		if (form && form.data){
            Object.keys(localStorage)
            .filter(x =>x.startsWith(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_`))
            .forEach(x => localStorage.removeItem(x))
			const sessionInfos = await getLocalStorageInfos(form.data.type.id);
			const infos = JSON.parse(JSON.parse(sessionInfos?.data?.data))
			// Vérifier si les propriétés existent avant de les définir dans le localStorage
			if (infos?.last_standard_section !== null && infos?.last_standard_section !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_standard_section`, infos?.last_standard_section);
			}

			if (infos?.last_section_submitted !== null && infos?.last_section_submitted !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, infos?.last_section_submitted);
			}
		
			if (infos?.preview !== null && infos?.preview !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`, infos?.preview);
			}

			if (infos?.filledSections !== null && infos?.filledSections !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`, infos?.filledSections);
			}
		
			if (infos?.final !== null && infos?.final !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`, infos?.final);
			}

			if (infos?.saved_num !== null && infos?.saved_num !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`, infos?.saved_num);
			}

			if (infos?.submit_id !== null && infos?.submit_id !== undefined) {
				localStorage.setItem(`${form.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`, infos?.submit_id);
			}
		}
    }

    const findPreviousStandardSection = (startIndex) => {
        for (let i = startIndex - 1; i >= 0; i--) {
          if (allSections[i].type === 'standard') {
            return i;
          }
        }
        return null;  // Aucune section standard trouvée avant l'index spécifié.
    };

    const goToLastSectionSubmitted = async (e) => {
        e.preventDefault();

        setMovement("forward");

        // Récupérer l'état actuel de formData
        var currentFormData = { ...formData };

        // Modifier la valeur de "answers" dans l'objet formData
        currentFormData.answers = [];

        // Mettre à jour l'état formData avec la nouvelle valeur
        setFormData(currentFormData);

        for (let i = 0; i < allSections.length; i++) {
            const element = allSections[i];
            if (element.id === parseInt(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`))){
                setCurrent(allSections.indexOf(element) + 1);
                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`,allSections.indexOf(element)  + 1)
            }
        }
    };

    const goToPreviousSection = async (e) => {
        e.preventDefault();

        setMovement("backward");

        // Récupérer l'état actuel de formData
        var currentFormData = { ...formData };

        // Modifier la valeur de "answers" dans l'objet formData
        currentFormData.answers = [];

        // Mettre à jour l'état formData avec la nouvelle valeur
        setFormData(currentFormData);

        if (current > 0) {
            setCurrent(current - 1);
            if (allSections && allSections[current]){
                await getSectionAnswers(allSections[current].id, localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`));
            }
        }
    };

    const Loading =()=>
    <div className="apdp-form-loading-main-div">
        <div className="apdp-form-loading">
            <div></div>
            <div></div>
        </div>
        <p>Chargement...</p>
    </div>

    function answerExists(question) {
        const tab = formData["answers"];
        return tab.some(function(el) {
            return el?.question === question;
        }); 
    }

    const updateFormData = (answerObject) => {
        const old = formData;
        if (!answerExists(answerObject?.question)){
            old["answers"].push(answerObject);
        } else {
            for (let i = 0; i <  old["answers"].length; i++) {
                const element =  old["answers"][i];
                if (element?.question === answerObject?.question){
                    old["answers"][old["answers"].indexOf(element)] = answerObject;
                }
            }
        }
        setFormData(old);
    }

    const loadForm = async () => {
        const resp = await getForm(window.location.pathname.split('/').pop())        
        if (resp.response && resp.response.status !== 200){
            // console.log(resp.response.header);
        } else {
            await setFormulaire(resp.data);
            try {
                await setFormSubmissionInfos(resp.data);
			    await sendFormSubmissionInfos(resp.data);
            } catch (error) {
                // console.log("Quelque chose s'est mal passé")
            }
            try {
                if (resp.data.data.sections.Standard || resp.data.data.sections.Annexe || resp.data.data.sections.Pj){

                    // const allSectionIds = Object.values(resp.data.data.sections).reduce((acc, val) => acc.concat(val), []).sort((a, b) => a.range - b.range);

                    // Filtrer et ordonner les sections standard
                    const standardSections = (resp.data.data.sections.Standard ?? [])
                    .reduce((acc, val) => acc.concat(val), [])
                    .sort((a, b) => a.range - b.range);

                    // Filtrer et ordonner les sections annexe
                    const annexesSections = (resp.data.data.sections.Annexe ?? [])
                    .reduce((acc, val) => acc.concat(val), [])
                    .sort((a, b) => a.range - b.range);

                    // Filtrer et ordonner les sections pj
                    const pjSections = (resp.data.data.sections.Pj ?? [])
                    .reduce((acc, val) => acc.concat(val), [])
                    .sort((a, b) => a.range - b.range);

                    const allSectionIds = [...standardSections, ...annexesSections, ...pjSections];

                    setAllSections(allSectionIds);
                    setStandardSections(standardSections);
                    setAnnexesSections(annexesSections);
                    setPjSections(pjSections);

                    const formQuery = await getRequestedQuery(localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`));
                    if (formQuery.data.data.status != 'pending'){
                        setCurrent(0)
                        localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, '0');
                    } else {
                        // Setting last section submitted and redirecting to the section after
                        if (isNaN(parseInt(localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`))) || localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`) === null || localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) == 'true' ) {
                            setCurrent(0)
                            localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, '0');
                        } else {
                            if (localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`) == 'true' && localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) == 'false') {
                                localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`, false);
                                localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, allSectionIds.at(-2)?.id);
                                setCurrent(allSectionIds.at(-2)?.id);
                            } else {
                                setCurrent(parseInt(localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`)));
                            }
                            for (let i = 0; i < allSectionIds.length; i++) {
                                const element = allSectionIds[i];
                                if (element.id === parseInt(localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`))){
                                    setCurrent(allSectionIds.indexOf(element) + 1);
                                    localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`,allSectionIds.indexOf(element)  + 1)
                                }
                            }
                        }
                    }

                    // Setting final variable to false
                    if (localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) != 'true'){
                        localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`,false)
                    }

                    // Setting preview variable to false
                    if (localStorage.getItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`) != 'true'){
                        localStorage.setItem(`${resp.data.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`, false);
                    }
                } else {
                    setAllSections([]);
                }
            } catch (error) {
                setLoadingError(true);
            }
        }
    }

    useEffect(async () => {
        if (localStorage.getItem("user_token") === "" || localStorage.getItem("user_token") === null){
            localStorage.setItem("redirect_url", window.location.pathname);
            window.location = "/auth";
        }
        loadForm();
    }, [])

    useEffect(() => {
        var existCondition = setInterval(async function() {
            if (window.jQuery('.col-sm-6').length) {
                clearInterval(existCondition);
                if (allSections && allSections[current]){
                    await getSectionAnswers(allSections[current].id, localStorage.getItem(`${formulaire?.data?.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`));
                }
            }
        }, 100);
		sendFormSubmissionInfos(formulaire);
    }, [current, formulaire]);

    useEffect(() => {
        // console.log(`La valeur de 'formData' a changé : ${JSON.stringify(formData)}`);
    }, [formData]);

    useEffect(() => {
        // console.log(`La valeur de 'allSections' a changé : ${allSections}`);
    }, [allSections]);

    useEffect(() => {
        console.log(`La valeur de 'showSubmit' a changé : ${showSubmit}`);
    }, [showSubmit]);

    useEffect(() => {
        // console.log(`La valeur de 'loadingResponses' a changé : ${loadingResponses}`);
    }, [loadingResponses]);

    if (!formulaire || !allSections && !loadingError) {
        return <Loading/>
    }

    function printForm() {
        var formContent = document.getElementById('recapContent').outerHTML;
        var printWindow = window.open('', '', 'height=400,width=800');
        printWindow.document.write('<html><head><title>APDP Formulaire</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(formContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    const getFormQuery = async (e) => {
        try {
            e.preventDefault();
        } catch (error) {
            // console.log("error", error)
        }
        let queryResponse = await getRequestedQuery(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`));
        queryAttemptsCounter += 1;
        setQuery(queryResponse.data.data);
        var answersFormArray = [];
        var questionsArray = [];
        for (let iterator = 0; iterator < allSections.length; iterator++) {
            const section = allSections[iterator];
            for (let newIter = 0; newIter < section.questions.length; newIter++) {
                let question = section.questions[newIter];
                question.index = allSections.indexOf(section);
                if (!questionsArray.includes(question)){
                    questionsArray.push(question);
                }
            }
        }

        if (allSections[allSections.length-1] == allSections[current]){
            setShowSubmit(true);
        }

        try {
            let temp_answers_form = [];
            let queryResponseReversed = queryResponse.data.data.answers;
            for (let a = 0; a < queryResponseReversed.length; a++) {
                const answer = queryResponseReversed[a];
                for (let newIdex = 0; newIdex < questionsArray.length; newIdex++) {
                    const question = questionsArray[newIdex];
                    var files = [];
                    if (answer.question_id == question.id) {
                        if (!temp_answers_form.includes(question.id)){
                            if (answer?.answer_file != null && answer?.answer_file.length > 0){
                                const processFiles = async () => {
                                    for (const file of answer?.answer_file) {
                                        try {
                                            const mediaFileResponse = await getMediaFile(answer?.id, file);
                                            const base64String = mediaFileResponse?.data?.data?.content;
                                            const fileLink = `data:${detectMimeType(base64String)};base64,` + base64String;
                                
                                            // Vérifiez si le fichier n'est pas déjà dans la liste avant de l'ajouter
                                            if (!files.some(existingFile => existingFile.url === fileLink)) {
                                                files.push({
                                                    url: fileLink,
                                                    fileName: file,
                                                });
                                            }
                                        } catch (error) {
                                            console.error('Erreur lors du traitement des fichiers', error);
                                            setShowSubmit(false);
                                        }
                                    }
                                };
                                await processFiles();
                                answersFormArray.push({'question_id': question.id, 'question': question.name, 'answer': answer.answer_text, 'files': Array.from(new Set(files)), 'id': question.index })
                            } else {
                                answersFormArray.push({'question_id': question.id, 'question': question.name, 'answer': answer.answer_text, 'id': question.index})
                            }
                            temp_answers_form.push(question.id);
                        }
                    }
                }
            }
            const orderedAnswersFormArray = answersFormArray.sort((a, b) => {
                return a.id - b.id;
            });
            setAnswersForm(orderedAnswersFormArray);
        } catch (error) {
            setShowSubmit(false);
            // // console.log("Not ready yet!!!")
        }
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        setMovement("forward");

        e.preventDefault();

        // if (allSections.length > 1){
        //     e.preventDefault();
        // }

        var questions = document.querySelectorAll('.col-sm-6');
        const validationCheckboxesResult = await validateCheckboxes(questions);
        const validationRadiosResult = await validateRadios(questions);

        if (validationCheckboxesResult === 200 && validationRadiosResult === 200){
            let responseSubmit;
            if (localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) != 'true'){
                try {
                    responseSubmit = await submitFormSection(formulaire.data.id, allSections[current].id, formData);
                } catch (error) {
                    responseSubmit = await submitFormSection(formulaire.data.id, parseInt(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`)), formData);
                }
                if (allSections?.length == 1){
                    responseSubmit = await submitEntireForm(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`), {});
                }
            } else {
                responseSubmit = await submitEntireForm(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`), {});
            }

            try {
                if (responseSubmit.status == 201 || responseSubmit.status == 200){
                    // Récupérer l'état actuel de formData
                    var currentFormData = { ...formData };

                    // Modifier la valeur de "answers" dans l'objet formData
                    currentFormData.answers = [];

                    // Mettre à jour l'état formData avec la nouvelle valeur
                    setFormData(currentFormData);
                    localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`,parseInt(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`))+1)
                    setResponseCounter(formData.answers.length);
                    const usefulQueryResponse = await getRequestedQuery(responseSubmit.data.data.saved_num);
                    setRefNumber(responseSubmit.data.data.saved_num);
                    localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`, responseSubmit.data.data.saved_num)
                    localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`, responseSubmit.data.data.submit_id)
                    if (responseSubmit.data.data['last_submitted_section'] != null){
                        localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, responseSubmit.data.data['last_submitted_section']);
                    } else {
                        if (localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) != 'true') {
                            localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, allSections[current].id);
                        } else {
                            localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`, 0);
                        }
                    }
                    if (localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) == 'true'){
                        localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`,0)
                        try {
                            if (responseSubmit.data.success){
                                toast.success("Formulaire soumis !!!",  {
                                    autoClose: 3000,
                                });
                                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`,0);
                                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submissionSuccess`,true);
                                localStorage.removeItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`);
                            } else {
                                toast.error(responseSubmit?.data?.message);
                                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submissionSuccess`,false)
                            }
                        } catch (error) {
                            toast.error(responseSubmit?.data?.message);
                        }
                        setLoading(false);
                    } else {
                        setMovement("forward");
                        setCurrent(current + 1);
                        setLoading(false)
                    }
                } else {
                    if (allSections.length > 1){
                        if (localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) == 'true'){
                                toast.success("Formulaire soumis !!!",  {
                                    autoClose: 3000,
                                });
                                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_filledSections`,0);
                                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submissionSuccess`,true);
                                localStorage.removeItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`);
                        } else {
                            toast.error(String(responseSubmit?.data?.message));
                        }
                    } else {
                        toast.error(String(responseSubmit?.data?.message));
                    }
                    setLoading(false)
                }
            } catch (error) {
                toast.error("Une erreur est survenue !!!");
                setLoading(false);
            }

            // Get section responses to display
            var existCondition = setInterval(async function() {
                if (window.jQuery('.col-sm-6').length) {
                    clearInterval(existCondition);
                    if (allSections && allSections[current]){
                        await getSectionAnswers(allSections[current].id, localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`));
                    }
                }
            }, 100);

            await sendFormSubmissionInfos(formulaire);
        } else {
            setLoading(false);
            setShowSubmit(false);
        }
    }

    try {
        standardBackgroundColor = standardSections.includes(allSections[current]) ? '#2b71d3' : false;
        annexeBackgroundColor = annexesSections.includes(allSections[current]) ? '#2b71d3' : false;
        pjBackgroundColor = pjSections.includes(allSections[current]) ? '#2b71d3' : false;
    } catch (error) {
        return (
            <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                <ToastContainer />
                <Form className="form-style" style={{alignItems: 'center'}}>
    
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ marginTop: '50%', marginBottom: '50%', }}>
                            <ImSad style={{ fontSize: '50px', marginBottom: '30px' }}/>
                            <p>Le formulaire est introuvable...</p>
                        </div>
                        <Button className="auth-form-btn" style={{ margin: "0px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#ffbe00", borderColor: '#ffbe00', color: "white", fontWeight: "bold" }} onClick={(e) => window.location.pathname = '/faire-une-demande'}>
                            Retour
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }

    if (formulaire && allSections?.length > 1){
        var stepper = <> <div style={{ width: "100%", margin: 10 }}>
            <ButtonGroup aria-label="Sections APDP" style={{ width: "80%" }} >
                { standardSections.length > 0 ? <Button style={{ fontSize: 20, pointerEvents: "none", backgroundColor: standardBackgroundColor }} active={true ? standardSections.includes(allSections[current]) : false}>Standards</Button> : null }
                { annexesSections.length > 0 ? <Button style={{ fontSize: 20, pointerEvents: "none", backgroundColor: annexeBackgroundColor }} active={true ? annexesSections.includes(allSections[current]) : false}>Annexes</Button> : null }
                { pjSections.length > 0 ? <Button style={{ fontSize: 20, pointerEvents: "none", backgroundColor: pjBackgroundColor }}  active={true ? pjSections.includes(allSections[current]) : false}>Pièces jointes</Button> : null }
            </ButtonGroup>
            </div>
            <div className='stepper-div'>
            <Stepper activeStep={current} connectorStyleConfig={{ disabledColor: 'white', activeColor: 'blue', size: 3 }} styleConfig={{ inactiveBgColor: 'white', inactiveTextColor: 'black', activeTextColor: 'white', activeBgColor: '#ffc107', completedBgColor: '#2b71d3', completedTextColor: 'white', size: 30, labelFontSize: '1.5rem', circleFontSize: '1.5rem' }} hideConnectors={false}>
                {formulaire && allSections.map((section) => (
                    <Step style={{margin: '0px 50px', outline: '4px solid white', outlineOffset: 2}} onClick={(e) => {
                        setMovement("backward");
                        setCurrent(allSections.indexOf(section));
                    }}/>
                ))}
            </Stepper>
            <h3 style={{ color: 'white' }}>{allSections[current]?.name}</h3>
        </div></>
    } else {
        var stepper = ""
    }

    try {
        if (formulaire && allSections.length > 1 && showSubmit){
            var endFormButton = <div id='button-submit'>  { answersForm ?  <Button style={{ marginTop: 20, padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#093d62", color: "white", fontWeight: "bold", display: 'block' }} className="auth-form-btn" type="submit"  onClick={(e) => {
                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`,true);
                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`,false);
            }}>
                Soumettre
            </Button> : null }</div>
        } else if(formulaire && allSections.length > 1 && allSections[allSections.length-1] == allSections[current] && !showSubmit){
            var endFormButton = <div id='button-next'><Button style={{ marginTop: 20, padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#093d62", color: "white", fontWeight: "bold", display: 'block' }} className="auth-form-btn" type="submit" onClick={async (e) => {
                if (document.getElementsByTagName('form')[0].checkValidity()){
                    localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`,true);
                    getFormQuery();
                }
            }}>
            Prévisualiser
        </Button></div>
        } else if(formulaire && allSections.length == 1){
            var endFormButton = <div id='button-submit'><Button  style={{ marginTop: 20, padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#093d62", color: "white", fontWeight: "bold", display: 'block' }} type="button"  onClick={(e) => {
                if (document.getElementsByTagName('form')[0].checkValidity()){
                    localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`,true);
                    localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`,false);
                    // handleSubmit();
                } else  {
                    document.getElementsByTagName('form')[0].reportValidity();
                }
            }}>
                Soumettre
            </Button></div>
        } else {
            var endFormButton = <div id='button-next'><Button  style={{ marginTop: 20, padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#093d62", color: "white", fontWeight: "bold", display: 'block' }} className="auth-form-btn" type="submit" onClick={(e) => {
                localStorage.setItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`,false);
            }}>
                Suivant
            </Button></div>
        }
    } catch (error) {
        return (
            <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                <ToastContainer />
                <Form className="form-style" style={{alignItems: 'center'}}>
    
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ marginTop: '50%', marginBottom: '50%', }}>
                            <ImSad style={{ fontSize: '50px', marginBottom: '30px' }}/>
                            <p>Le formulaire est introuvable...</p>
                        </div>
                        <Button className="auth-form-btn" style={{ margin: "0px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#ffbe00", borderColor: '#ffbe00', color: "white", fontWeight: "bold" }} onClick={(e) => window.location.pathname = '/faire-une-demande'}>
                            Retour
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }

    async function validateCheckboxes(questions) {
        var isValid = true; // Changeons par défaut à true et modifions seulement si on trouve une erreur
        var data = {
            status: 200,
            result: isValid
        };
    
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];
            var checkboxes = question.querySelectorAll('input[type="checkbox"][data-required="true"]');
    
            if (checkboxes.length > 0) {
                var isChecked = Array.from(checkboxes).some(function(checkbox) {
                    return checkbox.checked;
                });
    
                if (!isChecked) {
                    isValid = false;
                    toast.error('Assurez-vous de cocher au moins une option obligatoire dans chaque question!!!');
                    data = {
                        status: 400,
                        result: false
                    };
                    break; // Si une erreur est trouvée, pas besoin de continuer
                }
            }
        }
    
        if (allSections && allSections[current]){
            await getSectionAnswers(allSections[current].id, localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`));
        }
        return data.status;
    }

    async function validateRadios(questions) {
        var isValid = true; // Changeons par défaut à true et modifions seulement si on trouve une erreur
        var data = {
            status: 200,
            result: isValid
        };
        var hasRequiredRadios = false;
    
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];
            var radios = question.querySelectorAll('input[type="radio"][data-required="true"]');
    
            if (radios.length > 0) {
                hasRequiredRadios = true;
                var isChecked = Array.from(radios).some(function(radio) {
                    return radio.checked;
                });
    
                if (!isChecked) {
                    isValid = false;
                    toast.error('Assurez-vous de sélectionner une option obligatoire!!!');
                    data = {
                        status: 400,
                        result: false
                    };
                    setShowSubmit(false); // On cache le bouton de soumission si une erreur est trouvée
                    break; // Si une erreur est trouvée, pas besoin de continuer
                }
            }
        }
    
        if (allSections && allSections[current]){
            await getSectionAnswers(allSections[current].id, localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submit_id`));
        }
    
        return data.status;
    }    
    
    try {
        if (loadingError){
            <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                    <ToastContainer />
                    <Form className="form-style" style={{alignItems: 'center'}}>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '50%', marginBottom: '50%', }}>
                                <ImSad style={{ fontSize: '50px', marginBottom: '30px' }}/>
                                <p>Le formulaire est introuvable</p>
                            </div>
                            <Button className="auth-form-btn" style={{ margin: "0px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#ffbe00", borderColor: '#ffbe00', color: "white", fontWeight: "bold", float: 'right' }} onClick={(e) => window.location.pathname = '/faire-une-demande'}>
                                Retour
                            </Button>
                        </div>
                    </Form>
            </div>
        } else if (allSections?.length == 0){
            return (
                <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                        <ToastContainer />
                        <Form className="form-style" style={{alignItems: 'center'}}>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ marginTop: '50%', marginBottom: '50%', }}>
                                    <ImSad style={{ fontSize: '50px', marginBottom: '30px' }}/>
                                    <p>Rien à afficher</p>
                                </div>
                                <Button className="auth-form-btn" style={{ margin: "0px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#ffbe00", borderColor: '#ffbe00', color: "white", fontWeight: "bold", float: 'right' }} onClick={(e) => window.location.pathname = '/faire-une-demande'}>
                                    Retour
                                </Button>
                            </div>
                        </Form>
                </div>
            )
        } else if (allSections[current]?.questions.length !== 0){
            return (
                <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                    <ToastContainer />
                    {stepper}
                    <div className={"query"} id={"query"} style={{ paddingTop: "40px", paddingBottom: "40px", width: "80%", margin: "auto"}}>
                        {/* Title */}
                        <h4 className={'page-title-query'} style={{background: "#4385f6 0% 0% no-repeat padding-box", paddingTop: "40px", paddingBottom: "40px", borderRadius: "5px 5px 0px 0px", fontWeight: 300, fontSize: 'x-large'}}>{formulaire.data.name.toUpperCase()}</h4>

                        { movement == "backward" && !loading ? 
                            <a 
                                href='#' 
                                style={{ fontSize: "15px", float: "right", fontFamily: 'Montserrat', position: 'relative', top: 20, right: "10%" }} 
                                onClick={(e) => {
                                    goToLastSectionSubmitted(e);
                                    if (allSections[allSections.length-1] == allSections[current]){
                                        setShowSubmit(true);
                                    }
                                }}>
                                Reprendre la soumission
                            </a>
                        : null }

                        <form onSubmit={handleSubmit} style={{background: "#ffffff", paddingTop: "40px", paddingBottom: "40px", paddingRight: "10%", paddingLeft: "10%", borderRadius: "0px 0px 5px 5px", textAlign: "left",}}>
                            {loadingResponses && !loading ? <h3>Chargement des réponses...</h3> : null }
                            { !loading ? <div className="row" style={{ width: 'auto' }}>
                                {formulaire && allSections[current].questions.map((field) => (
                                    <div className="col-sm-6">
                                        <CustomInput key={field.id} field={field} updateValue={updateFormData}/>
                                    </div>
                                ))
                                }
                            </div> : <Loading/>}
                            { allSections[0]?.id != allSections[current]?.id && !loading && !loadingResponses ? <div id='form-buttons' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                <div id='button-previous'><Button  style={{ marginTop: 20, padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#093d62", color: "white", fontWeight: "bold", display: 'block' }} className="auth-form-btn" type="button" onClick={(e) => {
                                    goToPreviousSection(e);
                                }}>
                                    Précédent
                                </Button></div>
                                {endFormButton}
                            </div> : ((!loading && !loadingResponses) && <div>
                                {endFormButton}
                            </div>) }
                        </form>
                    </div>
                </div>
            )
        } else {
            if (movement == 'backward'){
                setCurrent(current - 1);
            } else {
                setCurrent(current + 1);
            }
        }
    } catch (error) {
        if (localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`) == "true") {
            return (
                <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                    <ToastContainer />
                    <Form className="form-style" onSubmit={handleSubmit}>
                        <h2 style={{ marginBottom: '50px', fontSize: 'large', fontFamily: 'Montserrat', }}>RECAPITULATIF </h2>
                        <span class="badge bg-secondary" style={{ fontFamily: 'Montserrat', fontSize: 14, margin: 10, padding: 15 }}>Info: Si vous ne voyez pas toutes vos réponses, vous pouvez cliquer sur le bouton 'Actualiser'.</span>
                        <div className="row" style={{ alignItems: 'center', marginBottom: '30px', }}>
                            <div className='col-sm-6'>
                                <h2 style={{ fontSize: 'large', float: 'left', fontFamily: 'Montserrat', }}>N° de dépôt: {localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`)}</h2>
                            </div>
                            <div className="col-sm-6">
                                <Button className="auth-form-btn" style={{ margin: "5px 10px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#ffbe00", borderColor: '#ffbe00', color: "white", fontWeight: "bold", float: 'right' }} onClick={(e) =>printForm()}>
                                    Imprimer la demande
                                </Button>
                                <Button className="auth-form-btn" style={{ margin: "5px 10px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "#1b4ce1", borderColor: '#1b4ce1', color: "white", fontWeight: "bold", float: 'right' }} onClick={()=> {
                                    setAnswersForm(null);
                                    getFormQuery();
                                }}>
                                    Actualiser
                                </Button>
                            </div>
                        </div>
                        <div id='recapContent' className="row" style={{ textAlign: 'left' }}>
                            { answersForm ? <div style={{ marginBottom: '30px' }}>
                                <table style={{ maxWidth: 'none', width: '100%', border: '2px solid rgb(9, 61, 98)' }}>
                                    {answersForm.map((answer) => (
                                            <tr style={{ border: '2px solid rgb(9, 61, 98)' }}>
                                                <td style={{ backgroundColor: 'rgb(9, 61, 98)', color: 'white',padding: '10px', width: '50%', fontFamily: 'Montserrat', }}>{answer.question}</td>
                                                <td style={{ padding: '10px', width: '50%', fontFamily: 'Montserrat', }}>{answer.answer ? answer.answer : <button  type='button' className='btn btn-primary' onClick={()=> downloadMultipleFiles(answer?.files)}>Télécharger</button>}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div> : <Loading/> }
                        </div>
                        <div id='form-buttons' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                            <div id='button-previous'><Button  style={{ marginTop: 20, padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "rgb(255, 190, 0)", borderColor: "rgb(255, 190, 0)", color: "white", fontWeight: "bold", display: 'block' }} className="auth-form-btn" type="button" onClick={(e) => {
                                goToPreviousSection(e);
                                setShowSubmit(false);
                            }}>
                                Modifier
                            </Button></div>
                            {endFormButton}
                        </div>
                    </Form>
                </div>
            )
        } else if (localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_final`) == 'true' && localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_preview`) == "false" && localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_submissionSuccess`) == 'true') {
            return (
                <div className="d-flex align-items-center justify-content-center py-2 flex-column" style={{backgroundColor : "#E2E2E2", paddingTop: "40px", paddingBottom : "40px"}}>
                    <ToastContainer />
                    <Form className="form-style" style={{alignItems: 'center'}}>

                        <h2 style={{ fontSize: 'xx-large', fontWeight: 'lighter', textTransform: 'uppercase' }}>Demande reçue</h2>
        
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '25%', marginBottom: '25%', }}>
                                <ImHappy style={{ fontSize: '50px', marginBottom: '30px' }}/>
                                <p style={{ fontFamily: 'Montserrat', }}>Votre demande a été soumise et enregistrée sous le numéro <b>{localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_saved_num`)}</b> et un mail vous a été envoyé.</p>
                            </div>
                            <Button className="auth-form-btn" style={{ margin: "0px", padding: "10px", width: "200px", borderRadius: "5px", fontSize: "15px", backgroundColor: "rgb(9, 61, 98)", borderColor: 'rgb(9, 61, 98)', color: "white", fontWeight: "bold" }} onClick={(e) => window.location.pathname = `/statut-demande`}>
                                Vérifier le statut
                            </Button>
                        </div>
                    </Form>
                </div>
            )
        } else {
            let index = localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`);
            if (typeof allSections[index] !== "undefined") {
                setCurrent(localStorage.getItem(`${formulaire.data.name.replaceAll(' ','_').toLowerCase()}_${localStorage.getItem('user_id')}_last_section_submitted`));
            } else {
                setCurrent(0);
            }
            setCurrent(0);
        }
    }
}
