import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
   baseURL: BASE_URL,
});

api.interceptors.response.use(function (response) {
   // Any status code that lie within the range of 2xx cause this function to trigger
   // Do something with response data
   // console.log(response)
   return response;
}, function (error) {
   // console.log(error)
   // Any status codes that falls outside the range of 2xx cause this function to trigger
   // Do something with response error
   return Promise.reject(error);
});


function getCookie(name) {
   const value = `; ${document.cookie}`;
   const parts = value.split(`; ${name}=`);
   if (parts.length === 2) return parts.pop().split(';').shift();
}


export function get_user(user_token) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/me`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${user_token}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

export function getFormById(form_id) {
   const response = api.get(`/formulaires/${form_id}`)
   return response.then(data => data).catch(error => error)
}

export function getRequestedQuery(ref_number) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/formulaires-soumis?query=${ref_number}`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem("user_token")}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            localStorage.removeItem('is_rt');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}

export function getSectionFormAnswers(submissionId, sectionId) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/formulaires-soumis/${submissionId}/sections/${sectionId}`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem("user_token")}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            localStorage.removeItem('is_rt');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}

export function getMediaFile(answer_id, file_name) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/download-file/answers/${answer_id}?name=${file_name}&format=base64`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem("user_token")}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

export function logout(user_token) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/auth/logout`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${user_token}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

export function register_or_login(route, data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/auth/${route}`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

export function setLocalStorageInfos(form_type_id, data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/temp-data/formulaires-soumis/${form_type_id}`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem("user_token")}`
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            localStorage.removeItem('is_rt');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}

export function getLocalStorageInfos(form_type_id) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/temp-data/formulaires-soumis/${form_type_id}`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem("user_token")}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            localStorage.removeItem('is_rt');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}


export function reinitPassword(email) {
   var config = {
      method: 'post',
      url: `https://api.apdp.bj/api/password/email`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
      },
      data: {
         email: email
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}




export function getForm(type) {
   const response = api.get(`/formulaires?type=${type}`)
   return response.then(data => data).catch(error => error.response)
}

export function getRequests() {
   var config = {
      method: 'get',
      url: `${BASE_URL}/utilisateur-formulaires-soumis`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem("user_token")}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

export function getAllFormTypes() {
   const response = api.get(`/formulaire-types?resource=formulaire`)
   return response.then(data => data).catch(error => error)
}

export function submitFormSection(formId, sectionId, data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/formulaires/${formId}/sections/${sectionId}/reponses`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('user_token')}`
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            localStorage.removeItem('is_rt');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}

export function submitEntireForm(submitId, data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/formulaires-soumission/handle/${submitId}?action=submission`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('user_token')}`
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            localStorage.removeItem('is_rt');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}

export function cancelSubmission(submitId, data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/formulaires-soumission/handle/${submitId}?action=cancel`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('user_token')}`
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => {
         if (String(error.response.data.message).includes('Unauthenticated')) {
            alert('Votre session a expiré');
            localStorage.removeItem('user_email');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_token');
            localStorage.removeItem('is_dpo');
            window.location = '/faire-une-demande';
         }
         return error.response;
      });
}

export function getAllArticles() {
   const response = api.get(`/articles`);
   return response.then(data => data).catch(error => error)
}
export function getAllArticleTypes() {
   const response = api.get(`/types/documentations/articles`);
   return response.then(data => data).catch(error => error)
}

export function getATypeOfArticles(type, pageNumber) {
   const response = api.get(`/articles?slug=` + `${type}&page=` + `${pageNumber}&categorie=` + `&section=` + ``)
   return response.then(data => data).catch(error => error)
}


export function getRightsOrDuties(type, categorie, section, pageNumber) {
   const response = api.get(`/articles?slug=` + `${type}&categorie=` + `${categorie}&page=` + `${pageNumber}&section=` + `${section}`)
   return response.then(data => data).catch(error => error)
}

export function getVisitsNumber(namespace) {
   const response = axios.get(`https://api.countapi.xyz/hit/${namespace}/key`)
   return response.then(data => data).catch(error => error)
}

export function getFaq(thematic) {
   const response = api.get(`/faqs?thematic=${thematic}`)
   return response.then(data => data).catch(error => error)
}

//Bureau members
export function getMembers() {
   const response = api.get(`/head-members`)
   return response.then(data => data).catch(error => error)
}


// Forums
export function getAllForums() {
   const response = api.get(`/forums`);
   return response.then(data => data).catch(error => error)
}

export function getAForum(id) {
   const response = api.get(`/forums/${id}`)
   return response.then(data => data).catch(error => error)
}

// Blog
export function getAllBlogs() {
   const response = api.get(`/blog-posts`);
   return response.then(data => data).catch(error => error)
}

export function getABlog(id) {
   const response = api.get(`/blog-posts/${id}`)
   return response.then(data => data).catch(error => error)
}

export function getArticlesResearched(searchedText, pageNumber, type, year) {
   const response = api.get(`/query/articles?query=${searchedText}&type=${type}&year=${year}&page=` + `${pageNumber}`)
   return response.then(data => data).catch(error => error)
}
export function getArticleDetails(id) {
   const response = api.get(`/articles/${id}`)
   return response.then(data => data).catch(error => error)
}

// Menus
export function getMenus() {
   const response = api.get(`/menus`);
   return response.then(data => data).catch(error => error)
}


// NewsLetter
export function submitNewsletterForm(data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/newsletter-subscribers`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

// DPO Formations
// List
export function getDPOFormations() {
   const response = api.get(`/formations`);
   return response.then(data => data).catch(error => error)
}

// get One
export function getDPOFormation(idForm) {
   const response = api.get(`/formations/${idForm}`);
   return response.then(data => data).catch(error => error)
}

// get One Episode
export function getFormationEpisode(idForm, idEpisode) {
   const response = api.get(`/formations/${idForm}/episodes/${idEpisode}`);
   return response.then(data => data).catch(error => error)
}

// Get All Trainings
export function getAllTraining(user_token) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/trainings`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${user_token}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}
// Get All Trainings Done
export function getAllTrainingDone(user_token) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/mytrainings`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${user_token}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}



// Get One Training
export function getOneTraining(idTraining, user_token) {
   var config = {
      method: 'get',
      url: `${BASE_URL}/trainings/${idTraining}`,
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${user_token}`
      }
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

// Participate to a training
export function participateToTraining(idTraining, user_token) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/trainings/${idTraining}`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${user_token}`

      },
      training: idTraining
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

// Forums

// Get forums 
export function getForums(pageNumber) {

   //  const response = api.get(`/forums`);
   const response = api.get(`/forums?size=10&page=${pageNumber}`);
   return response.then(data => data).catch(error => error)
}

// Get a forum
export function getForum(idForum) {
   const response = api.get(`/forums/${idForum}`);
   return response.then(data => data).catch(error => error)
}


// Add a comment to a forum
export function addCommentForum(idForum, user_token, comment) {
   const formData = new FormData();

   const header = {
      'Authorization': `Bearer ${user_token}`
   }
   formData.append("body", comment)
   const response = api.post(`/forums/${idForum}/comments`, formData, {
      headers: header
   })

   return response
      .then(response => response)
      .catch(error => error);
}
// Update a comment in a forum 

export function updateCommentForum(idForum, user_token, comment, idComment) {
   var config = {
      method: 'put',
      url: `${BASE_URL}/forums/${idForum}/comments/${idComment }`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${user_token}`

      },
      body: comment
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

// Submit additional info to forms
export function submitAdditionalInfo(submitId, data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/formulaires-soumis/${submitId}/infos-sup`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('user_token')}`
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}

// Récupération de la liste des thématiques
export function getBookingThematics() {
   const response = api.get(`/booking-settings`);
   return response.then(data => data).catch(error => error)
}

// Récupération des disponibilités sur une thématique
export function getBookingsAvailability(thematicId, date) {
   const response = api.get(`/booking-settings/thematics/${thematicId}?date=${date}`);
   return response.then(data => data).catch(error => error)
}
// Vérifier la disponibilité d'une date
// export function checkBookingAvailability(date, time) {
//    const response = api.get(`/booking-settings/check-availibility?date=${date}&time=${time}`);
//   return response.then(data => data).catch( error => error)
// }


// Faire une réservation
export function bookAppointment(data) {
   var config = {
      method: 'post',
      url: `${BASE_URL}/bookings`,
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
      },
      data: data
   };

   return axios(config)
      .then(response => response)
      .catch(error => error);
}