
import React, {useState, useEffect} from "react";
import UserTypeCard from "../../components/UserTypeCard/UserTypeCard";
import "./Home.css";
import { Row } from "react-bootstrap";
import RightDutyCard from "../../components/RightDutyCard/RightDutyCard";
import ShortcutCard from "../../components/ShortcutCard/ShortcutCard";
import dots from "../../assets/icons/dots.svg";
import services_illustration from "../../assets/icons/services_illustration.svg";
import FaqSection from "../../components/FaqSection/FaqSection";
import img5 from "../../assets/images/img5.jpg";
import img6 from "../../assets/images/img6.jpg";
import img7 from "../../assets/images/img7.jpg";
import CardOnCaroussel from "../../components/CardOnCaroussel/CardOnCaroussel";
import ActualiteCarousel from "../../components/ActualiteCarousel/ActualiteCarousel";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import countapi from 'countapi-js';

import { getArticlesResearched, getATypeOfArticles, getVisitsNumber } from "../../http/http";
import { DOMAIN_URL } from "../../constants/Constant";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import HeroSection from "../../components/HeroSection/HeroSection";
import { useParams } from 'react-router-dom'


const Home = () => {
  const navigate = useNavigate();

  const { menu } = useParams()

  const [articles, setArticles] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)

  const shortcuts = [
    { "label": "Mentions legales" },
    { "label": "Plaintes" },
    { "label": "Activités" },
    { "label": "Lois" }
  ]

  const handleClickCard = (cardClicked) => {
    navigate(`/${cardClicked}`);
  }
   
  useEffect(() => {
      localStorage.removeItem('active-menu')
  }, []);

    const [visitsNumber, setVisitsNumber] = React.useState();
    const [search, setSearch] = React.useState("");
    const [articlesResearched, setArticlesResearched] = React.useState();
    const loadVisitsNumber = async () => {
      const resp = await getVisitsNumber(DOMAIN_URL)
      if (resp.response && resp.response.status !== 200) {
        console.log("error ", resp.response)
      } else {
        console.log("data ", resp)
        if (resp?.data?.value != undefined) {
          setVisitsNumber(resp?.data?.value)
          localStorage.setItem("visits", resp?.data?.value);
        } else {
          setVisitsNumber(0)
          localStorage.setItem("visits", 0);
        }
      }
    }

    const handleResearch = async () => {
      const response = await getArticlesResearched(search)
      if (response.response && response.response.status !== 200) {
        // console.log("Error Searched", response.response)
      } else {
        setArticlesResearched(response.data)
      }
    }
    const loadShortcutsLink = async () => {
      // console.log("subMenu load", menu)
      const resp = await getATypeOfArticles("liens_utiles", 1)
      if (resp.response && resp.response.status !== 200) {
        // console.log("data error ", resp.response)
      } else {
        console.log("data shortcut data load", resp.data)
        const perPageValue = resp?.data?.meta?.per_page
        setPerPage(perPageValue)
        const total = resp?.data?.meta?.total;
        setTotalPage(Math.ceil(total / perPageValue))
  
        setArticles(resp.data.data)
        setIsLoading(false)
      }
  
  
    }

    React.useEffect(() => {
      handleResearch()
    }, [search])
    React.useEffect(() => {
      loadVisitsNumber();
      loadShortcutsLink();
    }, [])

    return (
      <div className={"Home"}>

        <ActualiteCarousel />
        {/* <HeroSection /> */}

        {/* User Type Section */}
        <div className={"d-flex flex-wrap user-type-card-container"}>
          <UserTypeCard
            handleClick={() => handleClickCard("actualites/mes_droits")}
            type={"right"}
            description={"Si un responsable de traitement estime qu’une demande..."}
          />
          <UserTypeCard
            handleClick={() => handleClickCard("actualites/mes_devoirs")}
            type={"duty"}
            description={"Vous avez la possibilité de bloquer l’accès de..."}
          />
          <UserTypeCard
            // handleClick={() => handleClickCard("faire-une-demande")}
            type={"demand"}
            description={"Tout citoyen peut s’adresser à l’APDP pour..."}
          />
          <UserTypeCard
            handleClick={() => handleClickCard("documentations/formulaires")}
            type={"forms"}
            description={"Pour la mise en conformité avec le régime de protection des données personnelles au Bénin..."}
          />
        </div>
        <Row className={"d-flex justify-content-end pr-5"}>
          <img className={"dots mr-5"} src={dots} />
        </Row>
        <br />
        <div className={"forms-section"}>
          <p className={"form-section-subtitle"}>Liens Rapides</p>
          <div className={"row"}>
            <div className={"col-sm-8 col-xs-12 mx-auto"} style={{ width: '80%' }}>
              <div className={"d-flex flex-wrap justify-content-evenly"}>
                {articles.length && articles.map(link => 
                <ShortcutCard className="mx-auto" ind={2} label={link.title} link={link.sub_title} />
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Home;
