import React, {useState, useEffect} from 'react';
import CustomInput from '../../components/CustomInput/CustomInput';
import Loader from '../../components/Loader/Loader';
import {Label, Form, Button, Input} from 'reactstrap';
import {ImSad, ImHappy} from "react-icons/im";
import './MesDemandes.css';
import {getRequests, getRequestedQuery} from '../../http/http';
import { ToastContainer, toast } from 'react-toastify';
import Pagination from '../../components/Pagination/Pagination';

export default function MesDemandes() {
    const [requests, setRequests] = useState(null);
    const [requestsToDisplay, setRequestsToDisplay] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [refNumber, setRefNumber] = useState(0);
    const [answersForm, setAnswersForm] = useState([]);
    const [loading, setLoading] = useState(true);
    

    const loadRequests = async () => {
        const resp = await getRequests();
        try {
            setRequests(resp?.data?.data)
            setRequestsToDisplay(paginateArray(resp?.data?.data, 4)[0])
        } catch (error) {
            console.log(error)
        }
        // console.log(resp?.data)
        setLoading(false)
    }

    function paginateArray(array, itemsPerPage) {
        const paginatedArray = [];
        let pageIndex = 0;
      
        while (pageIndex < array.length) {
          paginatedArray.push(array.slice(pageIndex, pageIndex + itemsPerPage));
          pageIndex += itemsPerPage;
        }
      
        return paginatedArray;
    }

    useEffect(() => {
        if (localStorage.getItem("user_token") === "" || localStorage.getItem("user_token") === null){
            localStorage.setItem("redirect_url", window.location.pathname);
            window.location = "/auth";
        }
        loadRequests();
    }, [])

    const Loading =()=>
    <div className="apdp-form-loading-main-div">
        <div className="apdp-form-loading">
            <div></div>
            <div></div>
        </div>
        <p>Chargement...</p>
    </div>

    return (
        <div className={"requests"} id={"requests"} style={{ paddingTop: "40px", paddingBottom: "40px", width: "80%", margin: "auto" }}>
            {/* Title */}
            <h4 className={'page-title-query'} style={{ background: "#4385f6 0% 0% no-repeat padding-box", paddingTop: "40px", paddingBottom: "40px", borderRadius: "5px", }}>MES DEMANDES</h4>


            {/* "Display user requests */}
            <section className="container" style={{ fontFamily: 'Montserrat' }}>
                <div 
                    className="button-col hidden-sm hidden-md hidden-lg hidden-xl" 
                    style={{
                        width: "fit-content"
                    }}
                >
                    <button 
                        className="btn btn-success" 
                        name="Nouvelle demande" 
                        onClick={(e)=>{
                            window.location.href = "/faire-une-demande"
                        }}
                        style={{
                            fontSize: "1.5rem",
                            padding: 10,
                            borderRadius: 0,
                            marginBottom: 10
                        }}> Nouvelle demande </button>
                </div>
                <header id='mobile-header' className='hidden-sm hidden-md hidden-lg hidden-xl'>
                    <div className="status-col">
                        <label> Statut </label>
                    </div>
                    
                    <div className="dates-col">
                        <label> Date de soumission </label>
                    </div>
                </header>
                <header className='hidden-xs' id='laptop-header'>
                    <div className="button-col">
                        <button 
                            className="btn btn-success" 
                            name="Nouvelle demande" 
                            onClick={(e)=>{
                                window.location.href = "/faire-une-demande"
                            }}
                            style={{
                                fontSize: "1.5rem",
                                padding: 10,
                                borderRadius: 0,
                            }}> Nouvelle demande </button>
                    </div>

                    <div className="status-col">
                        <label> Statut </label>
                    </div>
                    
                    <div className="dates-col">
                        <label> Date de soumission </label>
                    </div>

                </header>

                { loading ? <Loading/> : null }

                { requestsToDisplay?.length > 0 && !loading ? <ul className="task-items">
                    {requestsToDisplay && requestsToDisplay.map((request, index) =>
                        <li className="item type1 row px-0 py-3 p-md-3" style={{ cursor: "pointer" }} onClick={(e)=>{
                            window.location.href = `/mes-demandes/${request?.form_header_id}?saved_num=${request?.saved_num}`
                        }}>
                            <div className="task col-12 col-md-7 mb-2 mb-md-0">
                                {/* <div className="icon"> </div> */}
                                <div className="name w-100 m-0" > {request?.label} </div>
                            </div>

                            <div className="dates col-6 col-md-3 m-0 order-md-2">
                                <div className="bar"> {new Date(request?.created_at).toLocaleString()} </div>
                            </div>

                            <div className="status col-6 col-md-2 m-0 order-md-1 text-md-right">
                                {request?.status == "pending" ? <div className="icon pending ms-auto"> </div> : null}
                                {request?.status == "rejected" ? <div className="icon off ms-auto"> </div> : null}
                                {request?.status == "cancelled" ? <div className="icon off ms-auto"> </div> : null}
                                {request?.status == "approved" ? <div className="icon ms-auto"> </div> : null}
                                {request?.status == "submitted" ? <div className="icon warning ms-auto"> </div> : null}

                                {request?.status == "additional_info" ? <div className="icon off ms-auto"> </div> : null}

                                {request?.status == "pending" ? <div className="text me-md-auto">{"en attente".toUpperCase()}</div> : null }
                                {request?.status == "rejected" ? <div className="text me-md-auto">{"rejeté".toUpperCase()}</div> : null }
                                {request?.status == "cancelled" ? <div className="text me-md-auto">{"annulé".toUpperCase()}</div> : null }
                                {request?.status == "approved" ? <div className="text me-md-auto">{"approuvé".toUpperCase()}</div> : null }
                                {request?.status == "submitted" ? <div className="text me-md-auto">{"soumis".toUpperCase()}</div> : null }
                                {request?.status == "additional_info" ? <div className="text me-md-auto">{"incomplet".toUpperCase()}</div> : null }
                            </div>
                        </li>
                    )}
                </ul> : (!loading &&
                <div style={{ marginTop: 20 }}>
                    <ImSad style={{ fontSize: 80, marginBottom: 20 }}/>
                    <p>Rien à afficher</p>
                </div>)}
            </section>

            {/* Display pagination */}
            {requests?.length > 4 ? <Pagination
                changePage={(e)=>{
                    setRequestsToDisplay(paginateArray(requests, 4)[e.selected])
                }}
                pageCount={Math.round(requests?.length / 4)}
                perPage={4}
            />: <div></div>
            }
        </div>
    )


}
