let months = ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Dec"]

export const convertDate = (date) => {
    let date_array = date?.split("-");
    return date_array && date_array[2] + " " + months[parseInt(date_array[1]) - 1] + " " + date_array[0]
}

export const convertTime = (time) => {
    let time_array = time?.split(":")
    return time_array && time_array[0] + "h" + time_array[1];
}

export const sanitizeInput = (input) => {
    // Remplacer les caractères spéciaux par leur équivalent HTML
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;'
    };
    const sanitizedInput = input.replace(/[&<>"'/]/g, (char) => map[char]);

    return sanitizedInput;
}
